<template>
  <div style="display:flex;flex-direction:column;height:100%;justify-content: space-between">
    <div style="display:flex;flex-shrink: 0;margin-bottom: 15px ">
      <el-form status-icon label-width="90px" style="width:50%;">
        <el-form-item label="Номер">
          <div style="display: flex;flex-wrap: nowrap;justify-content: space-between">
            <div v-if="editOrder.id > 0" style="display: flex">
              <b><a target="_blank" :href="`http://gene-baza.evrogen.net/ru/Order.php?order=${editOrder.number}`">{{ editOrder.number }}</a></b>
            </div>
            <div v-else>Новый заказ - еще нет номера</div>
            <div style="display: flex;justify-content: flex-end">
              <my-el-button
                  type="primary"
                  size="mini"
                  :icon="letterIsSend('newOrder') ? 'el-icon-check':''"
                  @click="sendNewOrderMail(metaorder)"
              >
                <i class="el-icon-message"/>
              </my-el-button>
              <my-el-button
                  type="success"
                  size="mini"
                  :icon="letterIsSend('srvResult') ? 'el-icon-check':''"
                  @click="onClickSendResults(metaorder)"
              >
                <i class="el-icon-message"/>
                Результаты
              </my-el-button>



              <div v-if="editOrder.state === 'newOrder'">
                <my-el-button
                    type="success"
                    @click="setState('awaitApprove')"
                    size="mini">Согласовывается
                </my-el-button>
              </div>
              <div v-if="editOrder.state === 'awaitApprove'">
                <my-el-button
                    type="success"
                    @click="setState('inProgress')"
                    size="mini">В производство
                </my-el-button>
              </div>
              <my-el-button
                  v-else-if="editOrder.state === 'waitDelivery'"
                  :disabled="!lockManager.canEdit"
                  @click="$emit('delivered-to-evrogen', {orderId: editOrder.id})"
                  type="success"
                  size="mini">Доставлен в Евроген
              </my-el-button>
              <my-el-button
                  v-else-if="editOrder.state === 'inProgress' ||editOrder.state === 'inCompile'"
                  :disabled="!lockManager.canEdit"
                  @click="$emit('order-state-change', { orderId: editOrder.id, newState: 'progressComplete' })"
                  type="success"
                  size="mini">
                  {{ 'Производство завершено' }}
              </my-el-button>
              <my-el-button
                  v-else-if="editOrder.state === 'progressComplete'"
                  :disabled="!lockManager.canEdit"
                  @click="onSetReadyOrDone"
                  type="success"
                  size="mini">
                {{ deliveryToClient ? 'Готов к отправке' : 'Завершен' }}
              </my-el-button>
              <my-el-button delete @click="$emit('delete-order', editOrder.id)"></my-el-button>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="Состояние">
          <div style="display: flex;justify-content: space-between">
            <b>{{ $getEnumValue('OrderStateEnum', editOrder.state) }}</b>
            <el-checkbox style="width: 140px"
                         :disabled="!['newOrder', 'waitDelivery', 'awaitApprove'].includes(editOrder.state)"
                         :value="isDeliveryExist('reverse')"
                         @change="onDeliveryToEvrogenChange">
              Доставка в Евроен
            </el-checkbox>
          </div>
        </el-form-item>
        <el-form-item label="Дедлайн">
          <div style="display:flex; justify-content: space-between">
            <el-date-picker
                v-model="editOrder.deadline"
                type="date"
                :picker-options="{firstDayOfWeek: 1}"
                placeholder="Select date and time">
            </el-date-picker>
            <el-checkbox style="width: 140px" v-model="deliveryToClient">Доставка клиенту</el-checkbox>
          </div>
        </el-form-item>
        <el-form-item label="Комментарий" style="margin-bottom: 0">
          <el-input type="textarea"
                    style="padding-bottom: 10px"
                    :autosize="{ minRows: 1, maxRows: 4}"
                    v-model="editOrder.comment"/>
        </el-form-item>
      </el-form>
      <div style="display: flex; flex-direction: column; width: 50%; border-left: 1px solid #DCDFE6;margin-left: 10px;align-items: flex-end">
        <el-form label-position="left" label-width="100px">

          <el-form-item label="Сумма" style="margin-bottom: 4px">
            <el-input-number class="left-number-input"
                             type="number" :min="0"
                             :controls="false"
                             :disabled="notDeletedElements.length > 0"
                             v-model.number="editOrder.price" style="width: 100px;text-align: left"></el-input-number>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div style="display: flex;flex: 1 1;height: 99%;width: 100%;overflow-y: hidden; ">
      <el-tabs v-model="activeTab" style="width: 100%;height: 100%;display:flex;flex-direction: column" @tab-click="onEditTabClick">
        <el-tab-pane label="Строки" name="table" style="height: 100%">
          <el-table
              :data="notDeletedElements"
              style="width: 100%;font-size: 16px"
              :border="true"
              height="100%"
              ref="srvTable"
              @selection-change="onRowsSelectionChange"
              v-ls-saver:srvTbl
          >
            <el-table-column type="index" width="65">
              <template slot-scope="scope">
                {{ scope.row.showNumber }}
              </template>
            </el-table-column>
            <el-table-column label="КАТ" :width="100" :resizable="true" prop="srvCatalog.cat">
              <template slot-scope="scope">
                <div v-if="$g('editSrvRow.id') === scope.row.id">
                  <el-autocomplete
                      popper-class="ac-product-popper"
                      value-key="cat"
                      v-model="catAutocompleteValue"
                      :fetch-suggestions="onAutocompleteRowSearch"
                      :select-when-unmatched="false"
                      style="width: 100%"
                      @input="onAutocompleteRowInput(scope.row)"
                      @select="onAutocompleteProductSelect(scope.row, $event)">
                    <template slot-scope="{ item }">
                      <div>{{ item.cat }} {{ item.name }}</div>
                    </template>
                  </el-autocomplete>
                </div>
                <div v-else>
                  {{ scope.row.srvCatalog ? scope.row.srvCatalog.cat : '' }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="Название" :resizable="true">
              <template slot-scope="scope">
                {{ scope.row.srvCatalog ? scope.row.srvCatalog.name : '' }}
              </template>
            </el-table-column>

            <el-table-column label="Кол-во, шт" :width="123" :resizable="true" prop="countAll">
              <template slot-scope="scope">
                <div v-if="$g('editSrvRow.id') === scope.row.id && scope.row.srvCatalog">
                  <el-input-number
                      v-model="scope.row.count"
                      @change="onChangeCount"
                      controls-position="right"
                      style="width:100px"
                      :min="1"></el-input-number>
                </div>
                <div v-else>
                  {{ scope.row.count }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="Цена" width="120" :resizable="true">
              <template slot-scope="scope" v-if="scope.row.srvCatalog">
                {{ $roundFmt(scope.row.price) }}
              </template>
            </el-table-column>
            <el-table-column label="Цена руками" :width="130" :resizable="true">
              <template slot-scope="scope">
                <div v-if="$g('editSrvRow.id') === scope.row.id && scope.row.srvCatalog">
                  <i v-if="scope.row.handPrice === null || scope.row.handPrice === undefined"
                     style=" color: blue"
                     class="el-icon-edit"
                     @click="scope.row.handPrice = 0"></i>
                  <div v-else style="display: flex;flex-wrap: nowrap; align-items: center">
                    <my-price-input v-model="scope.row.handPrice" style="width: 90px"></my-price-input>
                    <i style="margin-left: 5px;font-weight: bold; color: red" class="el-icon-close" @click="scope.row.handPrice = null"></i>
                  </div>
                </div>
                <div v-else>
                  {{ scope.row.handPrice || scope.row.handPrice === 0 ? $roundFmt(scope.row.handPrice) : '' }}
                </div>
              </template>
            </el-table-column>

            <el-table-column label="Сумма" width="100" :resizable="true">
              <template slot-scope="scope">
                {{ $roundFmt(calcRowSum(scope.row)) }}
              </template>
            </el-table-column>

            <el-table-column width="110" :resizable="false">
              <template v-if="(scope.row.id || scope.row.id === 0) " slot-scope="scope">
                <template v-if="$g('editSrvRow.id') === scope.row.id">
                  <my-el-button key="btn1" cancel @click="onBtnCancelEditProductClick(scope.row)"/>
                  <my-el-button key="btn2" check :disabled="orderRowIsError(scope.row)" @click="onBtnApplyEditSrvClick"/>
                </template>

                <div v-else style="display: flex;flex-wrap: nowrap">

                  <my-el-button key="btn3" edit
                                :disabled="!!errorsForEditDeleteSrvPopover()"
                                :dis-popover="errorsForEditDeleteSrvPopover()"
                                @click="setEditSrv(scope.row)"/>
                  <my-el-button key="btn4" delete
                                :disabled="!!errorsForEditDeleteSrvPopover()"
                                :dis-popover="errorsForEditDeleteSrvPopover()"
                                @click="onBtnDeleteProductClick(scope.row)"/>
                </div>
              </template>
              <template v-slot:header>
                <my-el-button
                    type="success"
                    :disabled="!!errorsForAddSrvPopover"
                    :dis-popover="errorsForAddSrvPopover"
                    @click="onBtnAppendSrvClick">Добавить
                </my-el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="Файлы" name="files" style="height: 100%">
          <Split style="height:100%;padding-top: 0;" direction="horizontal">
            <SplitArea :min-size="400" style="display:flex;flex-direction: column; padding:0 5px 0 0; height:100%">
              <Split style="height: 100%" direction="vertical">
                <SplitArea :min-size="100" style="display:flex;flex-direction: column;justify-content: stretch; padding:5px 0 5px 5px; height:100%">
                  <el-input type="textarea"
                            style="padding-bottom: 0;height:99%;display: flex"
                            v-model="editOrder.description"/>
                </SplitArea>
                <SplitArea :min-size="100" style="display:flex;flex-direction: column; padding:5px 0 0 5px; height:100%">
                  <div style="display: flex; flex:none">Результаты</div>
                  <file-table v-if="!this.metaorder.isArchive" :files="editOrder.orderFiles" :file-type="['subOrderSrvResult']"/>
                  <div v-else
                       class="my-link"
                       @click="$emit('set-active-tab','files')"
                       style="display:flex;flex-direction:column; justify-content: center;align-self: center;height:100%">
                    Файлы заказа спрятаны в архив
                  </div>


                </SplitArea>
              </Split>
            </SplitArea>
            <SplitArea :min-size="400" style="display: flex;flex-direction: column">
              <Split style="height: 100%" direction="vertical">
                <SplitArea :min-size="100" style="display:flex;flex-direction: column;justify-content: stretch; padding:5px 0 5px 5px; height:100%">
                  <div style="display: flex; flex:none">
                    Файлы от заказчика
                  </div>
                  <div style="display: flex; flex: 1 1">
                    <file-table v-if="!this.metaorder.isArchive"  :files="editOrder.orderFiles" :file-type="['subOrderUserFiles']"/>
                    <div v-else
                         class="my-link"
                         @click="$emit('set-active-tab','files')"
                         style="display:flex;flex-direction:column; justify-content: center;align-self: center;height:100%;align-items: center;width:100%">
                      Файлы заказа спрятаны в архив
                    </div>
                  </div>
                </SplitArea>
                <SplitArea :min-size="100" style="display:flex;flex-direction: column; padding:5px 0 0 5px; height:100%">
                  <div style="display: flex; flex:none">Файлы</div>
                  <file-table  v-if="!this.metaorder.isArchive" :files="editOrder.orderFiles" :file-type="['subOrderManagerFiles']"/>
                  <div v-else
                       class="my-link"
                       @click="$emit('set-active-tab','files')"
                       style="display:flex;flex-direction:column; justify-content: center;align-self: center;height:100%;align-items: center;width:100%">
                    Файлы заказа спрятаны в архив
                  </div>

                </SplitArea>
              </Split>
            </SplitArea>
          </Split>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import fileTable from '@/components/common/fileTable';
import { findOrCreateDelivery } from '@/utils/orders';
import baseOrderTab from '@/components/order/BaseOrderTab';
import tableSaver from '@/utils/tableSaver';
import moment from 'moment-business-days';
import _ from  'lodash';
import { confirm } from "@/components/common/dialogs/dialogUtils";
import mailEditor from "@/components/mailsAndTemplates/MailEditor.vue";

export default {
  name: 'serviceTab',
  mixins: [baseOrderTab, tableSaver],
  components: {
    fileTable
  },
  props: ['metaorder', 'editOrder', 'lockManager', 'deliverys'],
  data() {
    return {
      editSrvRow: null,
      activeTab: 'table',
      catAutocompleteValue: ''
    }
  },
  methods: {
    async onClickSendResults() {
      let mail = await this.$mailUtils.buildSrvResultMail(this.metaorder, this.deliverys, this.editOrder);
      let accountType = 'srv-result';
      mail = await this.$showWindowAsync(mailEditor, { mail, mode: 'send', actionBeforeSend: null, accountType,
        noFilesMessage: this.metaorder.isArchive
            ? 'Для работы с файлами сначала нужно во вкладке "Файлы"  достать файлы из архива' : ''
      });
      if (!mail) {
        return;
      }
      this.lockerIsWatch = false;
      this.$nextTick(() => {
        this.metaorder.mails.push(mail);
        this.lockerIsWatch = true;
      });
    },

    async onAutocompleteRowSearch(search, cb) {
      search = search.trim();
      if (search.length < 1) {
        cb([]);
        return;
      }
      const query = {
        currentPage: 1,
        pageSize: 100,
        orderBy: 'Cat',
        orderIsDesc: false,
        query: [
          { fieldName: 'cat', op: 'like', values: [search] },
          { fieldName: 'name', op: 'like', values: [search] },
        ]
      }
      const result = (await this.$store.dispatch('srvCatalog/loadItems', query)).items;
      cb(result);
    },

    onBtnCancelEditProductClick(row) {
      const idx = _.findIndex(this.editOrder.elements, item => item.id === row.id);
      if (row.id === 0) {
        this.editOrder.elements.splice(idx, 1);
      } else {
        this.editOrder.elements.splice(idx, 1, this.editSrvRow);
      }
      this.editSrvRow = null;
      this.catAutocompleteValue = '';
      this.calcOrder(this.editOrder);
    },

    onBtnApplyEditSrvClick() {
      if (this.editSrvRow.id === 0) {
        let minId = _.min(this.editOrder.elements.map(item => item.id));
        minId = (!minId || minId >= 0) ? -1 : (minId - 1);
        const row = _.find(this.editOrder.elements, p => p.id === 0);
        row.id = minId;
      }
      this.editSrvRow = null;
      this.catAutocompleteValue = '';
      this.calcOrder(this.editOrder);
      this.$refs[ 'srvTable' ].sort('srvCatalog.cat');
    },

    calcOrder(order) {
      order.price = order.elements.filter(row => !row.forDelete).reduce((acc, row) => acc + this.calcRowSum(row), 0);
    },

    calcRowSum(row) {
      const price = (row.handPrice === null || row.handPrice === undefined)
          ? row.price
          : row.handPrice;
      return price * row.count;
    },

    errorsForEditDeleteSrvPopover() {
      if (this.isAdminEditMode) {
        return '';
      }
      if (this.errorsForAddNgsPopover()) {
        return this.errorsForAddNgsPopover();
      }
    },

    errorsForAddNgsPopover() {
      if (!this.lockManager.canEdit) {
        return "Заказ редактирует другой пользователь.";
      }
      if (!['waitDelivery', 'inProgress', 'awaitApprove'].includes(this.editOrder.state)) {
        return 'Заказ не в состоянии когда можно добавлять, изменять или удалять элементы заказа.';
      }
      return (this.editSrvRow ? 'Для добавления, удаления или изменения продукта завершите редактирование элемента заказа.<br/>' : '');
    },

    setEditSrv(row) {
      if (row.handPrice === null) {
        row.handPrice = undefined;
      }
      this.editSrvRow = _.cloneDeep(row);
      this.catAutocompleteValue = row.srvCatalog.cat;
    },

    async onBtnDeleteProductClick(product) {
      if (!await confirm('Удалить строку?', 'Удалить')) {
        return;
      }
      const idx = this.editOrder.elements.indexOf(product);
      if (this.editOrder.elements[ idx ].id > 0) {
        this.editOrder.elements[ idx ].forDelete = true;
      } else {
        this.editOrder.elements.splice(idx, 1);
      }
      this.calcOrder(this.editOrder);
    },







    onAutocompleteRowInput(row) {
      row.srvCatalog = null;
    },

    onAutocompleteProductSelect(row, srvCatalog) {
      row.srvCatalog = srvCatalog;
      row.price = srvCatalog.price;
      row.srvCatalogId = srvCatalog.id;
      row.count = 1;
    },


    orderRowIsError(row) {
      return !row.srvCatalog
          || !row.count
    },


    onBtnAppendSrvClick : function() {
      let maxPrior = 1;
      if (this.editOrder.elements.length > 0) {
        maxPrior = _.max(this.editOrder.elements.map(el => el.prior)) + 1;
      }
      this.editOrder.elements.push(
          {
            id: 0,
            count: 1,
            srvCatalog: null,
            srvCatalogId: null,
            orderId: this.editOrder.id,
            price: 0,
            forDelete: false,
            prior: maxPrior,
            handPrice: null
          });
      this.editSrvRow = { id: 0 };
    },



    onSetReadyOrDone() {
      let newState;
      if (this.deliveryToClient) {
        let delivery = this.deliverys.find(d => d.type === 'simple' && d.ordersRl.some(rl => !rl.forDelete && rl.orderId === this.editOrder.id));
        delivery.scheduledSendDate = moment().businessAdd(1).format('YYYY-MM-DDT00:00:00');
        newState = 'readyToSend';
      } else {
        newState = 'done';
      }
      this.$emit('order-state-change', { orderId: this.editOrder.id, newState });
    },

    async setState(newState) {
      this.$emit('order-state-change', { orderId: this.editOrder.id, newState });
    },

    async turnDelivery(newValue, type) {
      if (!newValue) {
        let delivery = this.deliverys.find(d => d.type === type && d.ordersRl.some(rl => rl.orderId === this.editOrder.id));
        if (delivery) {
          let rl = delivery.ordersRl.find(rl => !rl.forDelete && rl.orderId === this.editOrder.id);
          if (rl.id) {
            rl.forDelete = true;
          } else {
            let idx = delivery.ordersRl.indexOf(rl);
            delivery.ordersRl.splice(idx, 1);
          }
          if (!delivery.ordersRl.some(rl => !rl.forDelete)) {
            if (delivery.id < 0) {
              let id = this.deliverys.findIndex(d => d.id === delivery.id);
              this.deliverys.splice(id, 1);
            } else {
              delivery.forDelete = true;
            }
          }
        }
        if (type === 'reverse') {
          this.$emit('order-state-change', { orderId: this.editOrder.id, newState: 'newOrder' });
        }
      } else {
        let delivery = findOrCreateDelivery(this.metaorder, this.deliverys, type);
        delivery.ordersRl.push({
                                 orderId: this.editOrder.id,
                                 deliveryId: delivery.id,
                                 order: this.editOrder,
                                 delivery,
                                 price: 0,
                                 forDelete: false
                               });
        if (type === 'reverse') {
          this.$emit('order-state-change', { orderId: this.editOrder.id, newState: 'waitDelivery' });
        }
      }
    },

    isDeliveryExist(type) {
      return this.deliverys.some(d => d.type === type && d.ordersRl.some(rl => !rl.forDelete && rl.orderId === this.editOrder.id));
    },

    async onDeliveryToEvrogenChange(newValue) {
      await this.turnDelivery(newValue, 'reverse');
    }
  },

  computed: {

    error() {
      if (this.editSrvRow) {
        return 'Нужно закончить редактирование сервиса';
      }
      if ((this.editOrder.type === 'mst' &&
              this.editOrder.elements
              .filter(e => !e.forDelete)
              .some(p => (!p.sequence.includes('*')) && (!p.primerModifierRls || p.primerModifierRls.filter(rl => !rl.forDelete).length === 0)))
          || (this.editOrder.type === 'sst' && this.editOrder.elements.filter(e => !e.forDelete).some(p => p.primerModifierRls && p.primerModifierRls.filter(rl => !rl.forDelete).length > 0))
      ) {
        return `Заказ c типом ${this.editOrder.type.toUpperCase()}  содержит праймеры другого типа. Это плохо. Поправить надо.`;
      }
      if ( !this.editOrder.elements.some(e => !e.forDelete) ) {
        return `Заказ должен содержать элементы чтоб сохраненным быть.`;
      }
      return '';
    },



    isAdminEditMode() {
      return this.$store.state.shared.currentUser.roles.some(r => r === 'EndOrderProductEditor');
    },

    notDeletedElements() {
      return this.editOrder.elements.filter(e => !e.forDelete);
    },

    deliveryToClient: {
      get() {
        return this.isDeliveryExist('simple');
      },
      async set(newValue) {
        await this.turnDelivery(newValue, 'simple');
      }
    }
  }
}
</script>

<style scoped>

</style>
