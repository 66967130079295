<template>
  <div v-if="isLoaded" style="display:flex;flex-direction:column;justify-content: stretch; height:100%">
    <div style="display:flex;flex:none">
      <el-form status-icon label-width="90px" style="width:50%;">
        <el-form-item label="Номер">
          <div style="display: flex;flex-wrap: nowrap;justify-content: space-between">
            <div style="display: flex">
              <div v-if="editOrder.id > 0" style="width:150px">
                <b><a target="_blank" :href="`http://gene-baza.evrogen.net/ru/Order.php?order=${editOrder.number}`">{{ editOrder.number }}</a></b>
              </div>
              <div v-else>Новый заказ - еще нет номера</div>
              <div v-if="metaorder.user && metaorder.user.fio" class="el-form-item__label">Заказчик: {{ metaorder.user.fio }}</div>
            </div>
            <div style="display: flex;justify-content: flex-end">
              <my-el-button
                  v-if="metaorder.payType === 'fromInvoice'"
                  type="primary"
                  size="mini"
                  :icon="letterIsSend('newOrderSeq_forPay') ? 'el-icon-check':''"
                  @click="sendNewOrderMail(metaorder, 'newOrderSeq', '_forPay')"
              >
                <i class="el-icon-message"/>
                Счет
              </my-el-button>


              <my-el-button
                  type="info"
                  size="mini"
                  icon="el-icon-printer"
                  @click="onClickPrintPdf"
              ></my-el-button>
              <my-el-button
                  v-if="Object.keys(sequenceFiles).length > 0 && ['inProgress', 'waitSendResult', 'readyToSend', 'send', 'done'].some(s => s === editOrder.state)"
                  type="success"
                  size="mini"
                  :icon="letterIsSend('seqLinq') ? 'el-icon-check':''"
                  @click="onClickSendResults"
              >
                <i class="el-icon-message"/> Результаты
              </my-el-button>
              <my-el-button
                  type="primary"
                  size="mini"
                  :icon="letterIsSend('newOrderSeq') ? 'el-icon-check':''"
                  @click="sendNewOrderMail(metaorder, 'newOrderSeq')"
              >
                <i class="el-icon-message"/>
              </my-el-button>
              <my-el-button
                  v-if="editOrder.state === 'waitFirstCheck'"
                  :disabled="!!errorsForBtnCheck"
                  :dis-popover="errorsForBtnCheck"
                  @click="setStateAfterFirstCheck()"
                  type="success"
                  size="mini">Проверен
              </my-el-button>
              <my-el-button
                  v-if="editOrder.state === 'waitPrimers'"
                  :disabled="!!errorsForBtnInProgress"
                  :dis-popover="errorsForBtnInProgress"
                  @click="onBtnSetOrderInProgress"
                  type="success"
                  size="mini">В произодство
              </my-el-button>
              <my-el-button
                  v-if="editOrder.state === 'newOrder'"
                  :disabled="!lockManager.canEdit"
                  @click="$emit('order-state-change', { orderId: editOrder.id, newState: 'waitDelivery' })"
                  type="success"
                  size="mini">
                Ждать доставки в Евроген
              </my-el-button>

              <my-el-button
                  v-if="editOrder.state === 'waitDelivery'"
                  :disabled="!lockManager.canEdit"
                  @click="$emit('delivered-to-evrogen', {orderId: editOrder.id})"
                  type="success"
                  size="mini">Доставлен в Евроген
              </my-el-button>
              <my-el-button
                  v-if="isShowDoneButton"
                  type="success"
                  size="mini"
                  @click="onBtnSetOrderState('done')"
              >
                Завершить
              </my-el-button>
              <my-el-button
                  v-if="isShowReadyToSendButton"
                  type="success"
                  size="mini"
                  @click="onBtnSetOrderState('readyToSend')"
              >
                Готов к отправке
              </my-el-button>
              <my-el-button delete @click="$emit('delete-order', editOrder.id)"></my-el-button>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="Состояние"> <!-- TODO: кажется это не надо -->
          <div style="display: flex; justify-content: space-between;">
            <div style="white-space: nowrap;" v-html="orderStateTitle"></div>
            <div style="display: flex;justify-content: flex-end; align-items: center; padding-right: 0!important;" class="el-form-item__label">
              Не ждать письмо с результатами
              <el-switch
                  :disabled="['readyToSend', 'send', 'done'].includes(editOrder.state)"
                  v-model="editOrder.noWaitSequenceResult" @change="onChangeWaitResult" style="margin-left: 10px"></el-switch>
              <my-el-button
                  style="margin-left: 10px"
                  v-if="editOrder.state === 'waitFirstCheck'"
                  :disabled="!!errorsForBtnCheck"
                  :dis-popover="errorsForBtnCheck"
                  @click="setWaitNewDelivery"
                  type="warning"
                  size="mini">Ждать новую доставку
              </my-el-button>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="Дедлайн">
          <div style="display: flex;flex-wrap: nowrap;justify-content: space-between">
            <el-date-picker
                v-model="editOrder.deadline"
                type="date"
                :picker-options="{firstDayOfWeek: 1}"
                placeholder="Выбрать">
            </el-date-picker>
            <el-form-item label-width="140px" label="Е-mail, результаты" style="margin-bottom: 0;width:100%">
              <el-input v-model="editOrder.mailsForResult"></el-input>
            </el-form-item>
          </div>
        </el-form-item>
        <el-form-item v-if="!!editOrder.userComment?.trim()" label="Пользователь">
          <el-input type="textarea"
                    style="padding-bottom: 0"
                    readonly
                    :autosize="{ minRows: 1, maxRows: 1}"
                    v-model="editOrder.userComment"/>
        </el-form-item>

        <el-form-item label="Комментарий" style="margin-bottom: 0">
          <el-input type="textarea"
                    style="padding-bottom: 0"
                    :autosize="{ minRows: 1, maxRows: 4}"
                    v-model="editOrder.comment"/>
        </el-form-item>
      </el-form>
      <div style="display: flex;flex-direction: column;  width: 50%; border-left: 1px solid #DCDFE6;margin-left: 10px">
        <div style="display: flex;justify-content: space-between">
          <template v-if="editOrder.elements.length > 0 ">
            <el-form label-position="left" label-width="180px" style="margin-left: 10px;margin-right: 10px">
              <counter-view @set-seq-option-operator-id="$emit('set-seq-option-operator-id', $event)" :order="editOrder" :all-managers="allManagers" v-model="editOrder.dnaExtractCount" editable
                            :all-counters="counters" counter="dnaExtract"
                            label="Выделение геномной ДНК"></counter-view>
              <counter-view @set-seq-option-operator-id="$emit('set-seq-option-operator-id', $event)" :order="editOrder" :all-managers="allManagers" v-model="editOrder.pcrCount" editable
                            :all-counters="counters" counter="pcr" label="ПЦР"></counter-view>
              <counter-view @set-seq-option-operator-id="$emit('set-seq-option-operator-id', $event)" :order="editOrder" :all-managers="allManagers" :all-counters="counters" counter="plasmidExtract"
                            label="Выделение плазмиды"></counter-view>
              <counter-view @set-seq-option-operator-id="$emit('set-seq-option-operator-id', $event)" :order="editOrder" :all-managers="allManagers" :all-counters="counters" counter="clean"
                            label="Очистка ПЦР-продукта"></counter-view>
              <counter-view @set-seq-option-operator-id="$emit('set-seq-option-operator-id', $event)" :order="editOrder" :all-managers="allManagers" :all-counters="counters" counter="cleanGel"
                            label="Очистка через гель"></counter-view>
              <counter-view @set-seq-option-operator-id="$emit('set-seq-option-operator-id', $event)" :order="editOrder" :all-managers="allManagers" :all-counters="counters" counter="cleanPcr"
                            label="ПЦР с очисткой ПЦР-прд."></counter-view>
              <counter-view @set-seq-option-operator-id="$emit('set-seq-option-operator-id', $event)" :order="editOrder" :all-managers="allManagers" :all-counters="counters" counter="cleanFromPiece"
                            label="Выделение из куска"></counter-view>
            </el-form>
            <el-form label-position="left" label-width="180px" style="margin-left: 10px;margin-right: 10px">
              <counter-view @set-seq-option-operator-id="$emit('set-seq-option-operator-id', $event)" :order="editOrder" :all-managers="allManagers" wo-operator :all-counters="counters"
                            counter="fragments" field-length="70px" label="Образцов"></counter-view>
              <counter-view @set-seq-option-operator-id="$emit('set-seq-option-operator-id', $event)" :order="editOrder" :all-managers="allManagers" wo-operator :all-counters="counters"
                            counter="primers" field-length="70px" label="Праймеров"></counter-view>
              <counter-view @set-seq-option-operator-id="$emit('set-seq-option-operator-id', $event)" :order="editOrder" :all-managers="allManagers" wo-operator :all-counters="counters"
                            counter="sequence" field-length="70px" label="Сиквенс"></counter-view>
              <counter-view @set-seq-option-operator-id="$emit('set-seq-option-operator-id', $event)" :order="editOrder" :all-managers="allManagers" wo-operator :all-counters="counters"
                            counter="fragmentAnalyze" field-length="70px" label="Фрагментный анализ"></counter-view>
            </el-form>
            <price-block :edit-order="editOrder"></price-block>
          </template>
        </div>
        <div style="display: flex; justify-content: space-between; align-items: center; border-top:1px solid #E4E7ED;margin-top: 5px;padding-top: 5px">
          <div style="display: flex">
            <template v-if="editOrder.primerDesignPrice && editOrder.contigCompilePrice">
              <el-form label-position="left" label-width="180px" style="margin-left: 10px;margin-right: 10px">
                <counter-view @set-seq-option-operator-id="$emit('set-seq-option-operator-id', $event)" :order="editOrder" :all-managers="allManagers" v-model="editOrder.primerDesignCount" editable
                              :all-counters="counters" counter="primerDesign"
                              label="Дизайн праймеров"></counter-view>
              </el-form>
              <el-form label-position="left" label-width="180px" style="margin-left: 10px;margin-right: 10px">
                <counter-view @set-seq-option-operator-id="$emit('set-seq-option-operator-id', $event)" :order="editOrder" :all-managers="allManagers" v-model="editOrder.contigCompileCount" editable
                              :all-counters="counters" counter="contigCompile"
                              label="Сборка контига"></counter-view>
              </el-form>
            </template>
          </div>
          <div style="display: flex;justify-content: flex-end;align-items: center">
            <div class="my-link" style="color: red;align-self: flex-end" v-if="metaorder.deliverySum > 0" @click="$emit('set-active-tab', 'delivery')">
              <i class="el-icon-warning"></i> Есть платная доставка
            </div>
          </div>
        </div>
      </div>
    </div>

    <div style="display: flex;flex: 1 1;height: 99%;width: 100%;overflow-y: hidden; ">
      <el-tabs v-model="activeTab" style="width: 100%;height: 100%;display:flex;flex-direction: column" @tab-click="onEditTabClick">
        <el-tab-pane label="Заказ" name="order" style="height: 100%">
          <el-table :data="tableRowsAll"
                    class="element-table"
                    :default-sort="{prop: 'prior' }"
                    style="width: 100%;font-size: 16px"
                    :border="true"
                    height="100%"
                    @selection-change="onFragmentSelectionsForSplitChange"
                    ref="elementTable"
                    :row-class-name="selectedRowClassName"
                    v-ls-saver:viewTbl
          >
            <el-table-column type="index" prop="prior" width="55"></el-table-column>
            <el-table-column
                type="selection"
                width="40"
            >
            </el-table-column>
            <el-table-column>
              <template slot-scope="scope">
                {{ scope.row.fragment.name }}
                <div v-if="scope.row.fragment.tubeTitle" v-html="` (на пробирке: ${scope.row.fragment.tubeTitle})`"></div>
              </template>
              <template v-slot:header>
                <div style="display: flex;justify-content: space-between;align-items: center;padding: 0">
                  Образец
                  <template v-if="!isNoteTabsWindow">
                  <el-select
                      v-if="canCreateNoteList()"
                      v-model="selectedNoteList"
                      :checkbox="false"
                      placeholder="Заметки"
                      @change="onChangeNoteListSelect"
                      clearable>
                    <template>
                      <el-option
                          v-for="list in sortedNoteLists"
                          :key="list.id"
                          :value="list.id">
                        <span style="float: left">{{ list.name }}</span>
                        <span style="float: right;margin-left: 10px">
                            <my-date :date="list.createdAt" hide-time></my-date>
                          </span>
                      </el-option>
                      <el-option-group label="">
                        <el-option label="Новый список" :value="0"/>
                        <el-option v-if="noteLists.length > 0" label="Просто посмотреть" :value="-1"/>
                      </el-option-group>
                    </template>
                  </el-select>
                  <div v-else-if="noteLists.length > 0" style="display: flex;align-items: center">
                    <div @click="onChangeNoteListSelect(-1)" style="color: #2196f3;cursor:pointer;">Заметки</div>
                  </div>
                  </template>

                  <my-el-button
                      v-if="canSplitOrder()"
                      @click="onToNewOrderClick"
                      popover="В отдельный заказ"
                      type="success" icon="el-icon-sort"></my-el-button>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="Тип" width="130">
              <template slot-scope="scope">
                {{ scope.row.fragment.type ? scope.row.fragment.type.name : '' }}
              </template>
            </el-table-column>
            <el-table-column label="Название праймера">
              <template slot-scope="scope">
                <template v-if="scope.row.primerRl">
                  {{ getName(scope.row.primerRl.primer) }}
                  <el-popover
                      v-if="scope.row.primerRl && getSequence(scope.row.primerRl.primer)"
                      placement="right"
                      trigger="hover">
                    <div style="display: flex" v-html="getSequence(scope.row.primerRl.primer)"></div>
                    <i class="el-icon-zoom-in" slot="reference" style="margin-left: 5px"></i>
                  </el-popover>
                </template>
              </template>
            </el-table-column>
            <el-table-column label="GC-буфер" width="80px" align="center">
              <template slot-scope="scope">
                <template v-if="scope.row.primerRl">
                  {{ scope.row.primerRl.gcBuffer ? '+' : '' }}
                </template>
              </template>
            </el-table-column>
            <el-table-column label="Размер ДНК, п.о." width="100" align="right">
              <template v-slot="scope">
                {{ scope.row.fragment?.dnaSize }}
              </template>
            </el-table-column>
            <el-table-column label="Проверка" width="140">
              <template v-slot="scope">
                <div v-if="scope.row.checkRl" style="display: flex;flex-wrap: nowrap; justify-content: space-between">
                  <el-dropdown
                      v-if="scope.row.checkRl.check.isDone && editOrder.state === 'waitCheck' && scope.row.checkRl.state !== 'ok'"
                      @command="scope.row.checkRl.state = $event"
                      trigger="click">
                                        <span class="el-dropdown-link"
                                              :style="scope.row.checkRl.state === 'reject' ? 'color:red' : ''">
                                            {{ $getEnumValue('CheckStateEnum', scope.row.checkRl.state) }}
                                            <i class="el-icon-arrow-down el-icon--right"></i>
                                        </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="reject">Брак</el-dropdown-item>
                      <el-dropdown-item command="rejectSkip">Брак - пропускаем</el-dropdown-item>
                      <el-dropdown-item command="rejectWait">Брак - ждем новой проверки</el-dropdown-item>
                      <el-dropdown-item command="insist">Ок - заказчик хочет</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                  <div v-else>{{ $getEnumValue('CheckStateEnum', scope.row.checkRl.state) }}</div>
                  <div @click="showCheckWindow(scope.row.checkRl)" class="my-link" style="margin-left: 5px">{{ scope.row.checkRl.check.number }}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="Плашка" width="120">
              <template slot-scope="scope">
                <div v-if="scope.row.primerRl.plate" class="my-link" @click="showPlate(scope.row.primerRl)">
                  {{ scope.row.primerRl.plate.number }}&nbsp;{{ scope.row.primerRl.platePoint }}
                </div>
              </template>
            </el-table-column>
            <el-table-column v-if="!metaorder.isArchive" width="150">
              <template slot-scope="scope">
                <div v-if="!['error', 'canceled', 'overload'].includes(scope.row.primerRl.state)" style="display: flex;justify-content: flex-start">
                  <div
                      v-for="file in getFileExtensionsForElement(scope.row)"
                      class="my-link"
                      style="padding-left: 5px"
                      @click="getSequenceFile(scope.row.primerRl.id, file.fileName )"
                      :key="file.extension">
                    <el-tag size="mini" :type="getFileExtColor(file.extension)">
                      {{ file.extension }}
                    </el-tag>
                  </div>
                </div>
              </template>
              <template v-slot:header>
                <div style="display: flex;justify-content: space-between;align-items: center;padding: 0">
                  Файлы
                  <my-el-button
                      v-if="sequenceFiles && Object.keys(sequenceFiles).length > 0"
                      @click="getSequenceZipFile"
                      popover="Все файлы в zip архиве"
                      type="success" icon="el-icon-download"></my-el-button>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="Состояние" width="140">
              <div slot-scope="scope" style="display:flex;flex-wrap: nowrap">
                <div v-if="editOrder.state === 'done'">
                  {{ $getEnumValue('SeqStateEnum', scope.row.primerRl.state) }}
                </div>
                <div v-if="['readyToSend', 'inProgress', 'waitSendResult'].includes(editOrder.state)">
                  <el-dropdown
                      style="display: flex"
                      trigger="click"
                      @command="setElementsState($event, [scope.row])">
                                     <span class="el-dropdown-link" style="display: flex; justify-content: space-between; white-space: nowrap">
                                           {{ getElementStateLabel(scope.row.primerRl) }}
                                         <i class="el-icon-arrow-down el-icon--right"></i>
                                     </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item
                          v-for="command in getAcceptedCommandForRls([scope.row])"
                          :command="command.command" v-html="command.title" :key="command.command"/>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </div>
              <template v-slot:header>
                <div style="display:flex;flex-wrap: nowrap">
                  <div v-if="fragmentSelectionsForSplit.length > 0 && ['readyToSend', 'inProgress', 'waitSendResult'].includes(editOrder.state)">
                    <el-dropdown
                        style="display: flex"
                        trigger="click"
                        @command="setElementsState($event)">
                                     <span class="el-dropdown-link" style="display: flex; justify-content: space-between; white-space: nowrap">
                                           Состояние
                                         <i class="el-icon-arrow-down el-icon--right"></i>
                                     </span>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item
                            v-for="command in getAcceptedCommandForRls()"
                            :command="command.command" v-html="command.title" :key="command.command"/>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </div>
                  <div v-else>Состояние</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="Особенность" width="140">
              <div slot-scope="scope" style="display:flex;flex-wrap: nowrap">
                <div v-if="editOrder.state === 'done'">
                  {{ scope.row.primerRl.feature }}
                </div>
                <div v-else style="display: flex;justify-content: space-between;width: 100%">
                  <div>
                    <div class="my-link" v-if="scope.row.primerRl.feature" @click="scope.row.primerRl.feature = null">x</div>
                  </div>
                  <el-dropdown
                      style="display: flex;margin-right: 15px"
                      v-model="scope.row.primerRl.feature"
                      trigger="click"
                      @command="scope.row.primerRl.feature=$event">
                                     <span class="el-dropdown-link" style="display: flex; justify-content: space-between; white-space: nowrap">
                                           {{ scope.row.primerRl.feature }}
                                         <i class="el-icon-arrow-down el-icon--right"></i>
                                     </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item
                          v-for="feature in features"
                          v-html="feature" :command="feature" :key="feature"/>
                      </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </div>
              <template v-slot:header>
                <div style="display:flex;flex-wrap: nowrap">
                  <div v-if="fragmentSelectionsForSplit.length > 0 && editOrder.state !== 'done' ">
                    <el-dropdown
                        style="display: flex"
                        trigger="click"
                        @command="setElementsFeature($event)">
                                     <span class="el-dropdown-link" style="display: flex; justify-content: space-between; white-space: nowrap">
                                           Особенность
                                         <i class="el-icon-arrow-down el-icon--right"></i>
                                     </span>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item
                            v-for="feature in features"
                            v-html="feature" :command="feature" :key="`group_${feature}`"/>
                        <el-dropdown-item :command="null">Очистить</el-dropdown-item>

                      </el-dropdown-menu>


                    </el-dropdown>
                  </div>
                  <div v-else>Особенность</div>
                </div>
              </template>



            </el-table-column>


          </el-table>
        </el-tab-pane>
        <el-tab-pane label="Редактирование" name="edit" style="height:100%">
          <Split v-if="isEditTabLoaded" style="height: 100%;" direction="horizontal">
            <SplitArea :min-size="400" style="display:flex;flex-direction: column; padding:5px 5px 0 0; height:100%">
              <div style="display:flex;flex-wrap: nowrap; justify-content: stretch; padding-bottom: 5px">
                <div style="display:flex;flex-wrap: nowrap; align-items: center;">
                  <el-select
                      v-model="acFragmentGroupSelect"
                      value-key="key"
                      :checkbox="false"
                      placeholder="Действие"
                      @change="onChangeFragmenGroupSelect"
                      clearable>
                    <template>
                      <el-option-group label="Тип">
                        <el-option
                            v-for="type in fragmentTypes"
                            :key="type.id"
                            :label="type.name"
                            :value="{action: 'type', data: type, key: `type_${type.id}`}"/>
                      </el-option-group>
                      <el-option-group label="Опции">
                        <el-option
                            v-for="item in $getEnum('FragmentOptionsEnum').filter(i => i.value !== 'cleanPcr')"
                            :key="item.value"
                            :label="item.name"
                            :value="{action:'options', data: item.value, key: `options_${item.value}`}"/>
                      </el-option-group>

                      <el-option-group label="">
                        <el-option label="Добавить ПЦР" :value="{action:'setPcr', data: true, key: ''}"/>
                        <el-option label="Удалить ПЦР" :value="{action:'setPcr', data: false, key: ''}"/>
                      </el-option-group>
                      <el-option-group label="">
                        <el-option label="Сиквенс" :value="{action:'setSequence', key: ''}"/>
                        <el-option label="Фрагмент. анализ" :value="{action:'setAnalyze', key: ''}"/>
                      </el-option-group>
                      <el-option-group label="Еще">
                        <el-option label="Отвязать праймеры" :value="{action:'unbind', key: 'unbind'}"/>
                        <el-option label="Удалить фрагменты" :value="{action:'delete', key: 'delete'}"/>
                      </el-option-group>
                    </template>
                  </el-select>
                </div>
                <div style="display: flex;justify-content: flex-end;flex-grow: 1">
                  <el-dropdown trigger="click" @command="onClickImport">
                    <my-el-button type="primary">Импорт<i class="el-icon-arrow-down el-icon--right"></i></my-el-button>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="fragments">Образцы</el-dropdown-item>
                      <el-dropdown-item command="primers">Праймеры</el-dropdown-item>
                      <el-dropdown-item command="all">Все</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </div>
              <el-table :data="tableRows"
                        :span-method="arraySpanMethod"
                        :row-class-name="tableRowClassName"
                        :default-sort="{prop: 'id', order: 'descending'}"
                        ref="tblFragments"
                        style="width: 100%;font-size: 16px;flex-grow: 1"
                        height="100%"
                        :border="true"
                        @selection-change="onFragmentSelectionChange"
                        v-ls-saver:fragmentTbl
              >
                <el-table-column
                    :selectable="(row)=>row.number === 0"
                    type="selection"
                    width="55">
                </el-table-column>

                <el-table-column width="250px">
                  <template slot-scope="scope">
                    <div class="my-link" @click="onBtnFragmentEdit(scope.row.fragment)">{{ scope.row.fragment.name }}</div>
                    <div v-if="scope.row.fragment.tubeTitle" v-html="`На пробирке: ${scope.row.fragment.tubeTitle}`"></div>
                    <div style="display:flex">
                      <div style="display:flex;flex-wrap:nowrap;margin-right: 10px">{{ scope.row.fragment.action === 'sequence' ? 'Сиквенс, ' : 'Фрагментный анализ' }}</div>
                      <div v-if="scope.row.fragment.action === 'sequence' && !scope.row.fragment.type" style="display:flex;flex-wrap:nowrap; color:red">Не выбран тип</div>
                      <div v-else style="display:flex;flex-wrap:nowrap" v-html="`${scope.row.fragment.type ? scope.row.fragment.type.name : ''}`"></div>
                      <el-tag v-if="scope.row.fragment.withPcr" style="margin-left: 3px">ПЦР</el-tag>
                    </div>
                    <div v-if="scope.row.fragment.action === 'sequence' && scope.row.fragment.option !== 'none' ">
                      {{ $getEnumValue('FragmentOptionsEnum', scope.row.fragment.option) }}
                    </div>
                  </template>
                  <template v-slot:header>
                    <div style="display: flex;justify-content: space-between;align-items: center;padding: 0">
                      Образец
                      <my-el-button plus @click="onBtnFragmentEdit(null)"></my-el-button>
                    </div>
                  </template>

                </el-table-column>
                <el-table-column label="Размер ДНК, п.о." width="80" align="right">
                  <template v-slot="scope">
                    {{ scope.row.fragment.dnaSize }}
                  </template>
                </el-table-column>
                <el-table-column label="Проверка" width="240">
                  <template slot-scope="scope">
                    <div v-if="scope.row.checkRl && scope.row.checkRl.check.isDone" style="display: flex;flex-direction: column">
                      <el-dropdown
                          v-if="editOrder.state === 'waitCheck' && scope.row.checkRl.state !== 'ok'"
                          @command="scope.row.checkRl.state = $event"
                          trigger="click">
                                                <span class="el-dropdown-link">
                                                    {{ $getEnumValue('CheckStateEnum', scope.row.checkRl.state) }}
                                                    <i class="el-icon-arrow-down el-icon--right"></i></span>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item command="rejectSkip">Брак - пропускаем</el-dropdown-item>
                          <el-dropdown-item command="rejectWait">Брак - ждем новой проверки</el-dropdown-item>
                          <el-dropdown-item command="insist">Ок - заказчик хочет</el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                      <div v-else>{{ $getEnumValue('CheckStateEnum', scope.row.checkRl.state) }}</div>
                      <div @click="showCheckWindow(scope.row.checkRl)" class="my-link" style="margin-left: 5px">{{ scope.row.checkRl.check.number }}</div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column width="55">
                  <template slot-scope="scope">
                    <my-el-button arrow
                                  v-if="scope.row.primerRl && scope.row.primerRl.primerId"
                                  @click="onClickRemovePrimerFromFragment(scope.row,  scope.row.primerRl.primerId === null )"></my-el-button>
                    <my-el-button minus
                                  v-if="scope.row.primerRl && scope.row.primerRl.primerId === null && scope.row.fragment.primersRls.length > 1"
                                  @click="onClickRemovePrimerFromFragment(scope.row,  scope.row.primerRl.primerId === null )"></my-el-button>
                  </template>
                </el-table-column>
                <el-table-column label="Название">
                  <template slot-scope="scope">
                    <div v-if="scope.row.primerRl" style="display: flex;flex-direction: column">
                      <div style="display:flex;justify-content: space-between;flex-wrap: nowrap">
                        <div style="display: flex;flex-wrap: nowrap">
                          {{ getName(scope.row.primerRl.primer) }}
                          <el-popover
                              v-if="scope.row.primerRl && getSequence(scope.row.primerRl.primer)"
                              placement="right"
                              trigger="hover">
                            <div style="display: flex" v-html="getSequence(scope.row.primerRl.primer)"></div>
                            <i class="el-icon-zoom-in" slot="reference" style="margin-left: 5px"></i>
                          </el-popover>
                        </div>
                        <div style="margin-left: 20px">{{ $getEnumValue('SeqStateEnum', scope.row.primerRl.state) }}</div>
                      </div>
                      <div style="margin-top: 3px">
                        <el-checkbox v-model="scope.row.primerRl.gcBuffer" style="margin-right: 3px"></el-checkbox>
                        GC-буфер
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column width="55">
                  <template slot-scope="scope">
                    <div style="display: flex; flex-wrap: nowrap;justify-content: center;">
                      <my-el-button delete @click="onBtnFragmentDelete(scope.row.fragment)"/>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </SplitArea>
            <SplitArea :min-size="400" style="padding-top: 5px">
              <div style="height: 100%;display: flex;flex-direction: column;overflow-y: hidden">
                <div style="display:flex; justify-content:space-between;flex-wrap: nowrap; padding-bottom: 5px">
                  <div>
                    <my-el-button
                        @click="onBtnBindClick" type="success" icon="el-icon-d-arrow-left">Привязать
                    </my-el-button>
                  </div>
                  <div v-if="primerSelections.length > 0" style="display: flex;flex-wrap: nowrap">
                    <el-input
                        placeholder="концентрация"
                        v-model="primerGroupConcentration" style="margin-right: 5px"></el-input>
                    <my-el-button check :disabled="!primerGroupConcentration" @click="onBtnPrimerGroupSetConcentration"></my-el-button>
                    <my-el-button delete @click="onBtnPrimerGroupDelete" style="margin-left: 20px"></my-el-button>
                  </div>
                </div>
                <el-table
                    :data="noDeletedPrimers"
                    :default-sort="{prop: 'id', order: 'descending'}"
                    style="width: 100%;font-size: 16px;flex-grow: 1"
                    height="100%"
                    :border="true"
                    ref="tblPrimers"
                    @selection-change="onPrimersSelectionChange"
                    v-ls-saver:primerTbl
                >
                  <el-table-column
                      type="selection"
                      width="35">
                  </el-table-column>
                  <el-table-column width="300">
                    <template slot-scope="scope">
                      <div style="display: flex;justify-content: space-between;align-items: center;">
                        <div class="my-link" @click="onBtnPrimerEdit(scope.row)">{{ getName(scope.row) }}</div>
                        <el-popover v-if="editOrder.elements.some(fr => fr.primersRls.some(el => el.primerId === scope.row.id))"
                                    placement="right"
                                    trigger="hover">
                          <div>
                            <div v-for="fragment in editOrder.elements.filter(fr => !fr.forDelete && fr.primersRls && fr.primersRls.some(el => !el.forDelete && el.primerId === scope.row.id))"
                                 :key="'frg_' + fragment.id">
                              {{ fragment.name }}
                            </div>
                          </div>
                          <i class="el-icon-info" slot="reference"></i>
                        </el-popover>
                      </div>
                    </template>
                    <template v-slot:header>
                      <div style="display: flex;justify-content: space-between;align-items: center;padding: 0">
                        Название
                        <my-el-button plus @click="onBtnPrimerEdit(null)"></my-el-button>
                      </div>
                    </template>


                  </el-table-column>
                  <el-table-column label="Праймер">
                    <template slot-scope="scope">
                      <div style="display: flex" v-html="getSequence(scope.row)"></div>
                    </template>
                  </el-table-column>
                  <el-table-column label="Концентрация" width="130">
                    <template slot-scope="scope">
                      {{ getConcentration(scope.row) }}
                    </template>
                  </el-table-column>
                  <el-table-column width="55">
                    <template slot-scope="scope">
                      <div style="display: flex; flex-wrap: nowrap;justify-content: center;">
                        <my-el-button delete
                                      @click="onBtnPrimerDelete(scope.row)"
                                      :disabled="!!errorsForBtnDeletePrimer(scope.row)"
                                      :dis-popover="errorsForBtnDeletePrimer(scope.row)"/>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </SplitArea>
          </Split>
        </el-tab-pane>
        <el-tab-pane label="Материалы" name="return" style="height:100%">
          <div style="display:flex;flex-direction:column;justify-content: stretch; height:100%">
            <div style="display: flex;flex:none;">
              <el-dropdown trigger="click" @command="setMaterialsForGroup">
                <el-button type="primary" :disabled="selectedMaterialFragments.length === 0 &&  selectedMaterialPrimers.length === 0 ">
                  Установить для отмеченных<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                      v-for="item in $getEnum('ActionAfterEndEnum')"
                      :command="item.value"
                      :key="item.value">{{ item.name }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <div style="display: flex;flex: 1 1 100%; overflow-y: hidden;justify-content: flex-start; padding-top: 10px ">
              <el-table :data="editOrder.elements"
                        style="font-size: 16px;"
                        height="100%"
                        :border="true"
                        ref="tableFragmentMaterial"
                        @selection-change="setSelectionMaterialFragments"
                        v-ls-saver:materialFragmentsTab
              >
                <el-table-column
                    type="selection"
                    width="35">
                </el-table-column>
                <el-table-column label="Образец">
                  <template slot-scope="scope">
                    {{ scope.row.name }}
                  </template>
                </el-table-column>
                <el-table-column label="" width="140">
                  <template slot-scope="scope">
                    <el-dropdown trigger="click" @command="scope.row.actionAfterEnd=$event">
                      <el-button type="primary">
                        {{ $getEnumValue('ActionAfterEndEnum', scope.row.actionAfterEnd) }}<i class="el-icon-arrow-down el-icon--right"></i>
                      </el-button>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item
                            v-for="item in $getEnum('ActionAfterEndEnum')"
                            :command="item.value"
                            :key="item.value">{{ item.name }}
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </template>
                </el-table-column>
              </el-table>
              <el-table :data="filterForPrimerMaterials"
                        ref="tablePrimerMaterial"
                        style="font-size: 16px;margin-left: 5px"
                        height="100%"
                        :border="true"
                        @selection-change="setSelectionMaterialPrimers"
                        v-ls-saver:materialPrimersTbl
              >
                <el-table-column
                    type="selection"
                    width="35">
                </el-table-column>
                <el-table-column label="Праймер">
                  <template slot-scope="scope">
                    {{ scope.row.name }}
                    <el-popover
                        v-if="scope.row && getSequence(scope.row)"
                        placement="right"
                        trigger="hover">
                      <div style="display: flex" v-html="getSequence(scope.row)"></div>
                      <i class="el-icon-zoom-in" slot="reference" style="margin-left: 5px"></i>
                    </el-popover>
                  </template>
                </el-table-column>
                <el-table-column label="" width="140">
                  <template slot-scope="scope">
                    <el-dropdown trigger="click" @command="scope.row.actionAfterEnd=$event">
                      <el-button type="primary">
                        {{ $getEnumValue('ActionAfterEndEnum', scope.row.actionAfterEnd) }}<i class="el-icon-arrow-down el-icon--right"></i>
                      </el-button>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item
                            v-for="item in $getEnum('ActionAfterEndEnum')"
                            :command="item.value"
                            :key="item.value">{{ item.name }}
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </template>
                </el-table-column>

              </el-table>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane :disabled="true" key="checkFilesTab" name="checkFilesTab">
          <div slot="label" style="display: flex;flex-wrap: nowrap;justify-content: space-between;align-items: center">
            <div v-if="!metaorder.isArchive" class="my-link" style="font-family:Times New Roman" @click="openFileControlWindow">Контроль файлов</div>
            <div v-else @click="$emit('set-active-tab','files')" class="my-link">Файлы заказа спрятаны в архив</div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>

    <change-monitor name="noteOrderMon"
                    :items="monitoringItems"
                    @change="onChangesElements"
                    ref="changeMonitor"></change-monitor>

  </div>
</template>

<script>
import _ from 'lodash';
import SeqPrimerEditor from './SeqPrimerEditor';
import { createNewSubOrder, removeOrder, createNewSubOrderForSplitSeq, seqOrderSummary } from '@/utils/orders';
import { confirm, alert, alertWithLog, confirmWithLog } from '@/components/common/dialogs/dialogUtils';
import fragmentEditor from './FragmentEditor';
import VueSplit from 'vue-split-panel';
import textEditor from '@/components/common/TextEditor';
import { loadPrimerPrice, recalcPrimerOrder } from '@/utils/orders';
import sequencePlateEditor from '@/components/sequence/SequencePlateEditor';
import Vue from "vue";
import baseOrderTab from './BaseOrderTab.js';
import tableSaver from '@/utils/tableSaver';
import { findOrCreateDelivery } from '@/utils/orders';
import sequenceCheckEditor from '../sequence/SequenceCheckEditor';
import PriceBlock from '@/components/order/PriceBlock';
import SeqFilesView from '@/components/order/SeqFilesView';
import mailEditor from "@/components/mailsAndTemplates/MailEditor";
import myDate from "@/components/common/myDate";
import ChangeMonitor from "@/components/common/changeMonitor";


Vue.use(VueSplit);

let counterView = {
  name: 'counterView',
  template:
      `
        <el-form-item v-if="editable || count" style="margin-bottom: 4px;">
          <el-input-number v-if="editable" :style="{width:fieldLength}" :min="0" controls-position="right" v-model="value" @input="$emit('input', $event)"></el-input-number>
          <el-input v-else readonly :value="count" :style="{width: fieldLength}"></el-input>
          <span v-if="sum" style="margin-left: 5px">{{ $roundFmt(sum) }} руб.</span>
          <div slot="label" style="display: flex; justify-content: center; flex-direction: column; line-height: 13px;height: 28px">
            {{ label }}
            <div style="display: flex;justify-content: space-between; flex-wrap: nowrap">
              <div v-if="count > 0 && !woOperator"
                   :class="canSetOptionOperator ? 'my-link' : ''"
                   style="white-space: nowrap;"
                   :style="{color: (operatorName?'':'red')}"
                   @click="onSelectOperator(counter)"
              >{{ operatorName || 'Не сделано' }}
              </div>
              <div>
                <i v-if="canSetOptionOperator && count > 0 && !woOperator && operatorName" @click="removeOperator" class="el-icon-close" style="margin-left: 5px"></i>
              </div>
            </div>

          </div>
        </el-form-item>`,
  props: {
    value: 0,
    allCounters: [],
    counter: { default: '' },
    label: { default: '' },
    editable: { type: Boolean },
    fieldLength: { default: '90px' },
    woOperator: { type: Boolean },
    allManagers: [],
    order: null
  },
  methods: {
    async removeOperator() {
      if (!this.canSetOptionOperator) {
        return;
      }

      if (!await confirm('Отменить выполнение операции?', 'Отменить')) {
        return;
      }
      this.$emit('set-seq-option-operator-id', { orderId: this.order.id, option: this.counter, operatorId: null });
    },

    async onSelectOperator() {
      if (!this.canSetOptionOperator) {
        return;
      }
      let managerId = this.order[ `${this.counter}OperatorId` ] || this.$store.state.shared.currentUser.id;
      let result = await this.$showWindowAsync(selectManagerWindow, { allManagers: this.allManagers, managerId });
      if (result !== null) {
        this.$emit('set-seq-option-operator-id', { orderId: this.order.id, option: this.counter, operatorId: result.id });
      }
    }
  },
  computed: {
    canSetOptionOperator() {
      return !['inProgress', 'readyToSend', 'send', 'waitSendResult', 'done'].includes(this.order.state);
    },

    operatorName() {
      return this.allManagers.find(o => o.id === this.order[ `${this.counter}OperatorId` ])?.fio;
    },
    count() {
      return this.allCounters[ this.counter ]?.count || 0;
    },
    sum() {
      return this.allCounters[ this.counter ]?.sum || 0;
    }
  }
}

let selectManagerWindow = {
  name: 'selectManagerWindow',
  template: `
    <component-window
        title="Выбор исполнителя"
        :maximize="false"
        @close="onBtnCancelClick">
      <div style="display: flex; flex-direction: column">
        <el-form label-position="top" status-icon :model="result" ref="form" rules="rules">
          <el-form-item label="Кто выполнил операцию?" prop="operator">
            <el-select
                v-model="result.operator"
                :multiple="false"
                placeholder=""
                value-key="id"
            >
              <el-option
                  v-for="operator in allManagers.filter(m => m.roles.some(r => r === 'manager Seq'))"
                  :value="operator"
                  :key="operator.id"
                  :label="operator.fio"/>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <template slot="footer">
        <div style="display:flex;justify-content: space-between;width: 100%">
          <div>
            <my-el-button @click="onBtnCancelClick" type="warning">Отменить</my-el-button>
          </div>
          <div>
            <my-el-button :disabled="!result.operator" @click="onBtnSelect" type="success">Выбрать</my-el-button>
          </div>
        </div>
      </template>
    </component-window>`,
  props: ['onClose', 'allManagers', 'managerId'],
  data() {
    return {
      rules: {
        operator: [this.$validateRuleRequired]
      },
      result: { operator: null },
      windowSize: { minHeight: 170, minWidth: 250, height: '170', width: '250' }
    }
  },
  mounted() {
    this.result.operator = this.allManagers.find(m => m.id === this.managerId);
  },
  methods: {
    onBtnCancelClick() {
      this.onClose(null);
      this.$emit('close');
    },
    async onBtnSelect() {
      try {
        await this.$refs.form.validate()
      } catch (ex) {
        return false;
      }
      this.onClose(this.result.operator);
      this.$emit('close');
    }
  },
};

let newNoteListWindow = {
  name: 'newNoteListWindow',
  template: `
    <component-window
        title="Новый список"
        :maximize="false"
        @close="onBtnCancelClick">
      <div style="display: flex; flex-direction: column">
        <el-form label-position="left" label-width="100px" :model="result" ref="form" :rules="rules">
          <el-form-item label="Название" prop="name">
            <el-input v-model="result.name"/>
          </el-form-item>
          <el-form-item label="Дата" prop="date">
            <el-date-picker
                v-model="result.date"
                type="date"
                placeholder="Выберите дату"
                :editable="true"
                default-time="00:00:00"
                :picker-options="{firstDayOfWeek: 1}"
            ></el-date-picker>
          </el-form-item>
        </el-form>
      </div>
      <template slot="footer">
        <div style="display:flex;justify-content: space-between;width: 100%">
          <div>
            <my-el-button @click="onBtnCancelClick" type="warning">Отменить</my-el-button>
          </div>
          <div>
            <my-el-button type="success" @click="onBtnSelect">Выбрать</my-el-button>
          </div>
        </div>
      </template>
    </component-window>`,
  props: ['onClose'],
  data() {
    return {
      rules: {
        name: [this.$validateRuleRequired],
        date: [this.$validateRuleRequired]
      },
      result: { name: '', date: new Date() },
      windowSize: { minHeight: 170, minWidth: 250, height: '170', width: '250' }
    }
  },
  methods: {
    onBtnCancelClick() {
      this.onClose(null);
      this.$emit('close');
    },
    async onBtnSelect() {
      try {
        await this.$refs.form.validate()
      } catch (ex) {
        return false;
      }
      this.onClose({ id: 0, name: this.result.name, createdAt: this.result.date });
      this.$emit('close');
    }
  },
};


export default {
  name: 'seqTab',
  components: { ChangeMonitor, PriceBlock, counterView, myDate },
  props: ['allManagers', 'metaorder', 'editOrder', 'lockManager', 'timestamp', 'mailTemplates', 'isVisible', 'seqStorePrimers', 'deliverys'],
  mixins: [baseOrderTab, tableSaver],
  inject: ['sendLinkMail'],
  data() {
    return {
      noteLists: [],
      selectedNoteList: null,
      activeTab: 'order',
      existPrimers: [],
      addFragmentName: '',
      acFragmentTypeValue: null,
      acFragmentGroupSelect: null,
      fragmentTypes: [],
      primerSelections: [],
      fragmentSelections: [],
      fragmentSelectionsForSplit: [],
      groupConcentration: '',
      primerGroupConcentration: '',
      sequenceFiles: [],
      selectedMaterialPrimers: [],
      selectedMaterialFragments: [],
      isLoaded: false,
      isEditTabLoaded: false,
      features: ['не вышел', 'двоит', 'короткий', 'шпилька', 'нк']
    }
  },

  watch: {
    'isVisible': function(value) {
      if (value) {
        this.$nextTick(() => this.isLoaded = true)
      }
    },

    'editOrder.discountPercent': function() {
      this.editOrder.discountPrice = this.calcOrderPrice(true);
    },

    computedOrderPrice(value) {
      this.editOrder.price = value;
      this.editOrder.discountPrice = this.calcOrderPrice(true);
    },

    'editOrder.rowVersion': function() {
      this.loadFileList();
    },

    filterForPrimerMaterials: {
      handler: function() {
        this.prepareDeliveryForMaterials();
      },
      deep: true
    },

    'editOrder.elements': {
      handler: function() {
        this.prepareOptionPrice();
        this.prepareDeliveryForMaterials();
      },
      deep: true
    }
  },

  async mounted() {
    (!this.editOrder.primers) && (this.editOrder.primers = []);
    await this.loadFileList();
    this.loadFragmentTypes();
    this.$nextTick(() => {
      if (!this.tableRowsAll.length) {
        this.isLoaded = true;
        this.isEditTabLoaded = true;
        this.activeTab = 'edit';
      } else {
        this.activeTab = 'order';
      }
    });
    if (this.editOrder.id <= 0) {
      this.editOrder.elementSequencePrice = this.prices.sequence;
      this.editOrder.elementFragmentAnalyzePrice = this.prices.fragmentAnalyze;
      this.editOrder.dnaExtractPrice = this.prices.dnaExtract;
      this.editOrder.pcrPrice = this.prices.pcr;
      this.editOrder.primerDesignPrice = this.prices.primerDesign;
      this.editOrder.contigCompilePrice = this.prices.contigCompile;
      this.editOrder.pcrCount = 0;
    }
    this.noteLists = await this.$store.dispatch('sequenceNotes/loadItems', {query: [{fieldName: 'isArchive', op: 'eq', values:[false]}]});
  },

  methods: {

    async onChangeNoteListSelect(listId) {
      this.selectedNoteList = null;
      let notes = this.fragmentSelectionsForSplit.map(fs => ({ id: 0, primerForSequenceRlId: fs.primerRl.id, noteListId: listId }));
      if (listId === 0) {
        let noteList = await this.$showWindowAsync(newNoteListWindow);
        if (noteList === null) {
          return;
        }
        noteList.notes = notes;
        let newList = await this.$store.dispatch('sequenceNotes/saveItem', noteList);
        this.noteLists.push(newList);
        listId = newList.id;
      } else if (listId !== -1) {
        await this.$store.dispatch('sequenceNotes/saveSequenceNotes', notes);
      } else {
        listId = '';
      }

      let wnd = window.open('', 'seqNoteTabs');
      wnd.location = `/sequenceNoteTabs#${listId}`;
      wnd.focus();
    },

    async onClickPrintPdf() {
      await this.$myHttp.postWithDownload('/api/orders/ExportSequencePdf', this.editOrder.id, `${this.editOrder.number}.pdf`);
    },


    async openFileControlWindow() {
      this.$showWindowAsync(SeqFilesView, { id: -1, editOrder: this.editOrder });
    },

    getElementStateLabel(rl) {
      if (rl.plateId) {
        return this.$getEnumValue('SeqStateEnum', rl.state);
      }
      return rl.state !== 'new' ? this.$getEnumValue('SeqStateEnum', rl.state) : 'В очереди';
    },
    getAcceptedCommandForRls(rows) {
      rows = rows || this.fragmentSelectionsForSplit;
      let result = [];

      let allOnPlate = rows.every(row => row.primerRl.plateId);
      let allNoPlate = rows.every(row => !row.primerRl.plateId);
      let allIsNotNew = rows.every(row => row.primerRl.state !== 'new');
      let allIsNew = rows.every(row => row.primerRl.state === 'new');
      if (allOnPlate && allIsNotNew) {
        result.push({ command: 'inProgress', title: 'В производстве' });
        result.push({ command: 'done', title: 'Готов' });
        result.push({ command: 'error', title: 'Брак' });
        result.push({ command: 'errorAndCopy', title: 'Брак + копия' });
        result.push({ command: 'overload', title: 'Перегруз + копия' });
      } else if (allOnPlate && allIsNew) {
        result.push({ command: 'new', title: 'В очередь' });
      } else if (allNoPlate) {
        result.push({ command: 'delete', title: 'Удален' });
        result.push({ command: 'new', title: 'В очередь' });
      }

      result.push({ command: 'copy', title: 'Копия' });
      result.push({ command: 'canceled', title: 'Отменен' });

      if (rows.length === 1 && !(allOnPlate && allIsNew)) {
        let idx = result.findIndex(r => r.command === rows[ 0 ].primerRl.state);
        if (idx >= 0) {
          result.splice(idx, 1);
        }
      }
      return result;
    },

    async setElementsFeature(command) {
      this.fragmentSelectionsForSplit.forEach(row => {
        row.primerRl.feature = command;
      })
    },

    async setElementsState(command, rows) {
      rows = rows || this.fragmentSelectionsForSplit;
      rows.forEach(row => {
        switch ( command ) {
          case 'inProgress':
          case 'done':
          case 'delete':
          case 'canceled':
          case 'error': {
            row.primerRl.state = command;
            break;
          }
          case 'new': {
            row.primerRl.plate = null;
            row.primerRl.plateId = null;
            row.primerRl.state = 'new';
            break;
          }
          case 'copy': {
            this.doCreateRowCopy(row, command);
            break;
          }
          case 'overload':
          case 'errorAndCopy': {
            row.primerRl.state = command === 'errorAndCopy' ? 'error' : 'overload';
            this.doCreateRowCopy(row, command);
            break;
          }
        }
      });
    },


    onEditTabClick(tab) {
      if (tab.name === 'edit') {
        this.isEditTabLoaded = true;
      }
    },

    async onChangeWaitResult() {
      this.$emit('order-state-change', { orderId: this.editOrder.id, newState: 'readyToSend' });
    },

    async onClickSendResults() {
      let mail = await this.$mailUtils.buildSeqResultMail(this.metaorder, this.deliverys, this.editOrder);
      let accountType = 'seq-result';
      mail = await this.$showWindowAsync(mailEditor, {
        mail, mode: 'send', actionBeforeSend: null, accountType,
        noFilesMessage: this.metaorder.isArchive
            ? 'Для работы с файлами сначала нужно во вкладке "Файлы"  достать файлы из архива' : ''
      });
      if (!mail) {
        return;
      }
      this.lockerIsWatch = false;
      this.$nextTick(() => {
        this.metaorder.mails.push(mail);
        this.lockerIsWatch = true;
      });
      if (this.editOrder.state === 'waitSendResult') {
        this.$emit('order-state-change', { orderId: this.editOrder.id, newState: 'readyToSend' });
      }
    },


    async createRowCopy(row) {
      if (!await confirm('Создать копию?')) return;
      this.doCreateRowCopy(row);
    },

    doCreateRowCopy(row, copyType) {
      if (!row.primerRl.copyNumber) {
        row.primerRl.copyNumber = 0;
      }
      let newRow = Object.assign({}, row.primerRl);
      newRow.id = undefined;
      newRow.plate = null;
      newRow.plateId = null;
      newRow.platePoint = null;
      newRow.state = 'new';
      newRow.copyNumber = Math.max(...row.fragment.primersRls.filter(rl => rl.primerId === row.primerRl.primerId).map(rl => rl.copyNumber)) + 1;
      newRow.copyType = copyType;
      newRow.feature = null;
      row.fragment.primersRls.push(newRow);
    },

    getFileExtColor(ext) {
      if (ext === 'seq') {
        return "success";
      } else if (ext === 'ab1') {
        return "warning";
      }
    },

    async setWaitNewDelivery() {
      if (await confirm(`Вернуть заказ в состояние 'Ожидает доставки в Евроген'?`)) {
        this.$emit('order-state-change', { orderId: this.editOrder.id, newState: 'waitDelivery' });
      }
    },

    setStateAfterFirstCheck() {
      /* Если в заказе только фрагментный анализ - не ждем проверку, сразу отправляем в производство
         let needCheck = this.editOrder.elements
         .some(el => !el.forDelete && el.action !== 'fragmentAnalyze' && el.primersRls.some(rl => !rl.forDelete));
         let newState = needCheck ? 'waitCheck' : 'inProgress';
      */
      this.$emit('order-state-change', { orderId: this.editOrder.id, newState: 'waitCheck' });
    },

    async onBtnSetOrderState(state) {
      if (this.letterIsSend('seqLinq') || await confirm("Кажется письмо не было послато. Все равно продолжить?")) {
        this.$emit('order-state-change', { orderId: this.editOrder.id, newState: state });
      }
    },

    prepareDeliveryForMaterials() {
      let deliveryIsNeed =
          this.editOrder.elements.some(r => r.actionAfterEnd === 'return')
          || this.filterForPrimerMaterials.some(r => r.actionAfterEnd === 'return');


      if (deliveryIsNeed && !this.simpleDeliveryForOrder) {
        let delivery = findOrCreateDelivery(this.metaorder, this.deliverys, 'simple');
        delivery.ordersRl.push({
                                 orderId: this.editOrder.id,
                                 deliveryId: delivery.id,
                                 order: this.editOrder,
                                 delivery,
                                 price: 0,
                                 forDelete: false
                               });
        return;
      }
      if (!deliveryIsNeed && this.simpleDeliveryForOrder) {
        let orderRl = this.simpleDeliveryForOrder.ordersRl.find(rl => !rl.forDelete && rl.orderId === this.editOrder.id);
        let saveDeliveryPtr = this.simpleDeliveryForOrder;
        orderRl.forDelete = true;

        //Доставку ставшую пустой можно удалить только в том случае если ее еще нет в базе.
        //Еслм она в базе то удалять надо на сервере
        if (saveDeliveryPtr.id <= 0 && saveDeliveryPtr.ordersRl.every(rl => rl.forDelete)) {
          let idx = this.deliverys.indexOf(saveDeliveryPtr);
          this.deliverys.splice(idx, 1);
        }
      }
    },

    async loadFileList() {
      if (this.editOrder.id > 0) {
        this.sequenceFiles = await this.$store.dispatch('files/getSequenceFileList', this.editOrder.id)
      }
    },

    async loadFragmentTypes() {
      this.fragmentTypes = this.$getEnum('seqFragmentTypes');
    },


    createFragment({ name, type, newId, tubeTitle, dnaSize }) {
      if (!newId) {
        newId = _.min(this.editOrder.elements.map(e => e.id));
        newId = (!newId || newId > 0) ? -1 : (newId - 1);
      }
      return {
        id: newId,
        primersRls: [],
        name: name || '',
        tubeTitle: tubeTitle || '',
        type: type || null,
        typeId: type ? type.id : null,
        action: 'sequence',
        option: 'none',
        actionAfterEnd: 'destroy',
        optionPrice: 0,
        sequenceCheckRls: [],
        withPcr: false,
        dnaSize
      };
    },

    async onBtnFragmentEdit(fragment) {
      fragment = fragment
          ? _.cloneDeep(fragment)
          : this.createFragment({});

      fragment = await this.$showWindowAsync(fragmentEditor, {
        allFragments: this.editOrder.elements,
        fragment: fragment ? _.cloneDeep(fragment) : this.createFragment({})
      });
      if (!fragment) {
        return;
      }

      if (fragment.action !== 'sequence') {
        fragment.primersRls = [];
        this.addPrimerToFragmentIfNeed(fragment, null);
        fragment.optionPrice = 0
      } else {
        let rlsForDelete = fragment.primersRls.find(rl => !rl.primerId);
        if (rlsForDelete) {
          if (rlsForDelete.id) {
            rlsForDelete.forDelete = true;
          } else {
            fragment.primersRls = fragment.primersRls.filter(rl => rl.primerId)
          }
        }
        fragment.optionPrice = fragment.option !== 'none' ? this.prices[ fragment.option ] : 0;
      }

      let idx = _.findIndex(this.editOrder.elements, el => el.id === fragment.id);
      if (idx < 0) {
        this.editOrder.elements.push(fragment);
      } else {
        this.editOrder.elements.splice(idx, 1, fragment);
      }
    },

    canSplitOrder() {
      return ['newOrder', 'waitDelivery', 'waitCheck', 'waitPrimers'].some(st => this.editOrder.state === st)
          && this.fragmentSelectionsForSplit.length > 0
          && this.fragmentSelectionsForSplit.length < this.tableRowsAll.length;
    },
    canCreateNoteList() {
      return this.fragmentSelectionsForSplit.length > 0;
    },

    async onToNewOrderClick() {
      if (!await confirm("Вынести выбранные элементы в отдельный заказ?")) {
        return;
      }

      let withNewDelivery = await confirm("Сформировать новую доставку для создаваемого заказа?", 'Сформировать', null, true);


      let oldOrder = this.editOrder;
      let newOrder = createNewSubOrderForSplitSeq(this.metaorder, this.editOrder, this.deliverys, this.$settings.deadlines, withNewDelivery);
      let movedFragments = _.uniq(this.fragmentSelectionsForSplit.map(r => r.fragment));
      let movedPrimers = this.fragmentSelectionsForSplit.map(r => r.primerRl.primer).filter(r => r);

      movedFragments.forEach(fragment => {

        let isFragmentMoveAll = allFragmentMove(fragment);
        let newFragment = copyFragment(newOrder, fragment, isFragmentMoveAll);

        fragment.primersRls.forEach(rl => {
          if (rl.primer === null) {
            addPrimerToNewFragment(newFragment, null);
          } else {
            if (!isPrimerForMove(rl.primer)) return;
            let newPrimer = findPrimerInNewOrder(newOrder, rl.primer);
            if (!newPrimer) {
              newOrder.primers.push(rl.primer);
              (!isFragmentMoveAll) && removePrimerFromFragment(fragment, rl);
              newPrimer = rl.primer;
            }
            addPrimerToNewFragment(newFragment, newPrimer);
          }
          rl.forDelete = true;
        });
        if (isFragmentMoveAll) {
          if (fragment.id > 0) {
            fragment.forDelete = true;
          } else {
            let idx = this.editOrder.elements.findIndex(el => el.id === fragment.id);
            this.editOrder.elements.splice(idx, 1);
          }
        }
      });

      newOrder.primers.forEach(primer => {
        if (linksToPrimerExist(oldOrder, primer)) {
          replacePrimerToCopy(newOrder, primer);
        } else {
          removePrimer(oldOrder, primer);
        }
      });

      function linksToPrimerExist(oldOrder, primer) {
        return oldOrder.elements.some(el => !el.forDelete && el.primersRls.some(rl => rl.primerId === primer.id));
      }

      function removePrimer(order, primer) {
        primer = order.primers.find(p => p.id === primer.id);
        if (primer.id > 0) {
          primer.forDelete = true;
        } else {
          let idx = _.findIndex(order.primers, p => p.id === primer.id);
          order.primers.splice(idx, 1);
        }
      }

      function replacePrimerToCopy(newOrder, primer) {
        let copy = _.cloneDeep(primer);
        let newId = _.min(newOrder.primers.map(p => p.id));
        copy.id = !newId || newId > 0 ? -1 : (newId - 1);
        let idx = newOrder.primers.indexOf(primer);
        newOrder.primers.splice(idx, 1, copy);
        newOrder.elements
        .reduce((acc, el) => acc.concat(el.primersRls.filter(rl => rl.primerId === primer.id)), [])
        .forEach(rl => {
          rl.primerId = copy.id;
          rl.primer = copy;
        });
      }

      function isPrimerForMove(primer) {
        return movedPrimers.some(p => p.id === primer.id);
      }

      function allFragmentMove(fragment) {
        return !fragment.primersRls.some(rl => rl.primerId !== null && movedPrimers.every(p => p.id !== rl.primerId))
      }

      function copyFragment(newOrder, fragment, isFragmentMoveAll) {
        let newFragment = Object.assign({}, fragment);
        newFragment.orderId = newOrder.id;
        newFragment.primersRls = [];
        if (!isFragmentMoveAll) {
          let newId = _.min(newOrder.elements.map(el => el.id));
          newFragment.id = !newId || newId > 0 ? -1 : (newId - 1);
        }
        newOrder.elements.push(newFragment);
        return newFragment;
      }

      function removePrimerFromFragment(fragment, rl) {
        let idx = fragment.primersRls.indexOf(rl);
        fragment.primersRls.splice(idx, 1);
      }

      function addPrimerToNewFragment(fragment, primer) {
        fragment.primersRls.push({
                                   orderId: 0,
                                   fragmentId: fragment.id,
                                   primerId: primer ? primer.id : null,
                                   primer: primer
                                 })
      }

      function findPrimerInNewOrder(newOrder, primer) {
        return _.find(newOrder.primers, p => p.id === primer.id)
      }
    },

    async onBtnFragmentDelete(fragment, isGroupOperation = false) {
      if (!isGroupOperation && !await confirm('Удалить образец?')) {
        return;
      }

      let idx = this.editOrder.elements.indexOf(fragment);
      if (this.editOrder.elements[ idx ].id > 0) {
        this.editOrder.elements[ idx ].forDelete = true;
      } else {
        this.editOrder.elements.splice(idx, 1);
      }

      (!isGroupOperation) && this.cleanNotUseStorePrimers();
    },

    async onChangeFragmenGroupSelect() {
      if (!this.acFragmentGroupSelect) {
        return;
      }
      let cmd = this.acFragmentGroupSelect;
      this.acFragmentGroupSelect = null;
      switch ( cmd.action ) {
        case 'unbind': {
          if (await confirm("Отвязать праймеры от выбранных образцов?")) {
            //Отвязываем только для фрагментов типа сиквенс, т.к. для фрагментного анализа должен оставаться relation
            this.fragmentSelections
            .filter(fr => fr.fragment.action === 'sequence')
            .forEach(fragmentRow => {
              fragmentRow.fragment.primersRls = fragmentRow.fragment.primersRls.filter(rl => rl.id > 0);
              fragmentRow.fragment.primersRls.forEach(rl => rl.forDelete = true);
            });
            this.cleanNotUseStorePrimers();
          }
          return;
        }
        case 'delete': {
          if (await confirm('Удалить выбранные образцы?')) {
            this.fragmentSelections.forEach(fragmentRow => {
              this.onBtnFragmentDelete(fragmentRow.fragment, true)
            });
            this.cleanNotUseStorePrimers();
          }
          return;
        }
        case 'options': {
          if (await confirm(`Установить опцию '${this.$getEnumValue('FragmentOptionsEnum', cmd.data)}' для выбранных образцов?`)) {
            this.fragmentSelections.forEach(fragmentRow => {
              fragmentRow.fragment.optionPrice = this.prices[ cmd.data ] || 0
              fragmentRow.fragment.option = cmd.data;
            });
          }
          return;
        }
        case 'setPcr': {
          const str = cmd.data ? "Добавить ПЦР для выбранных образцов?" : "Удалить ПЦР для выбранных образцов?"
          if (str) {
            this.fragmentSelections.forEach(fragmentRow => {
              fragmentRow.fragment.withPcr = cmd.data;
            });
          }
          return;
        }


        case 'type': {
          if (await confirm(`Установить тип '${cmd.data.name}' для выбранных образцов?`)) {
            this.fragmentSelections.forEach(fragmentRow => {
              fragmentRow.fragment.type = cmd.data;
              fragmentRow.fragment.typeId = cmd.data.id;
            });
          }
          return;
        }
        case 'setSequence': {
          if (!await confirm(`Установить 'Секвенирование' для выбранных образцов?`)) {
            return;
          }
          this.fragmentSelections.forEach(fragmentRow => {
            if (fragmentRow.fragment.action === 'sequence') {
              return;
            }
            fragmentRow.fragment.action = 'sequence';
            fragmentRow.fragment.primersRls = fragmentRow.fragment.primersRls.filter(rl => rl.id > 0);
            fragmentRow.fragment.primersRls.forEach(rl => rl.forDelete = true);
          });

          break;
        }
        case 'setAnalyze': {
          if (!await confirm(`Установить 'Фрагментный анализ' для выбранных образцов?`)) {
            return;
          }
          this.fragmentSelections.forEach(fragmentRow => {
            if (fragmentRow.fragment.action === 'fragmentAnalyze') {
              return;
            }
            fragmentRow.fragment.action = 'fragmentAnalyze';
            fragmentRow.fragment.type = null;
            fragmentRow.fragment.typeId = null;
            fragmentRow.fragment.optionPrice = 0;
            fragmentRow.fragment.option = 'none';
            fragmentRow.fragment.primersRls = fragmentRow.fragment.primersRls.filter(rl => rl.id > 0);
            fragmentRow.fragment.primersRls.forEach(rl => rl.forDelete = true);
            this.addPrimerToFragmentIfNeed(fragmentRow.fragment, null);
          });
          break;
        }

      }
    },

    async onBtnPrimerGroupSetConcentration() {
      if (!await confirm('Изменить концентрацию для выбранных преаймеров?')) {
        return;
      }
      this.primerSelections
      .filter(p => p.storeType === 'fromUser')
      .forEach(p => p.concentration = this.primerGroupConcentration)
    },

    async onBtnPrimerGroupDelete() {
      if (!await confirm('Удалить выбранные праймеры?')) {
        return;
      }
      this.primerSelections
      .filter(p => this.canEditPrimer(p))
      .forEach(p => this.onBtnPrimerDelete(p, false));
    },

    async onClickImport(importType) {
      let importDescription = {
        fragments: {
          title: 'Импорт образцов',
          placeholder: 'название;[f;][тип образца;][ 1 | 2 | 3 | 4 ]' +
              '<br/>   f если присутствует - фрагментный анализ' +
              '<br/>   1 - очистка; 2 - очистка ПЦР; 3 - очистка через гель; 4 - Выделение плазмиды',
          fn: this.doImportFragments
        },
        primers: { title: 'Импорт праймеров', placeholder: 'название; праймер; 1 -  требуется синтез ', fn: this.doImportPrimers },
        all: { title: 'Импорт всего', placeholder: '№ п.п.; название образца; название праймера; концентрация праймера; тип образца; размер ДНК; праймер; пробирка', fn: this.doImportAll }
      };
      await this.$showWindow(textEditor, importDescription[ importType ], async srcText => {
                               if (!srcText) {
                                 return true;
                               }
                               let preparedLines = this.prepareImportLines(srcText);
                               let errors = await importDescription[ importType ].fn(preparedLines);
                               if (errors && errors.length > 0) {
                                 await alertWithLog('Были ошибки:', errors.reduce((acc, err) => acc + (acc ? '\n' : '') + err.message, ''));
                                 return false;
                               } else if (errors) { // дурацкий ход для того что б не показывать повторно окошко для не критичных ошибок
                                 return false;
                               }
                               return true;
                             }
      );
    },

    getDuplicateByName(elements) {
      return _(elements)
      .map(f => f.name.toLowerCase().replace(/ /g, ''))
      .filter((val, i, arr) => _.includes(arr, val, i + 1))
      .map(name => `Дублируется название: ${name}`)
      .value();
    },

    prepareImportLines(srcText) {
      return srcText
      .split('\n')
      .map(line => this.removeExcessDelimeter(line)
      .split('\t')
      .map(item => item.trim()));
    },

    //В экселе второй разделитель почему то состоит из двух табов - отсюда танцы с бубной
    removeExcessDelimeter(src) {
      let number = 0;
      let replacer = (str) => {
        number++;
        return (number === 2 && str.length > 1)
            ? str.substring(1)
            : str;
      };
      return src.replace(/\t+/g, replacer);
    },

    doImportFragments(colsArr) {
      let newId = _.min(this.editOrder.elements.map(e => e.id));
      newId = (!newId || newId > 0) ? -1 : (newId - 1);
      let errors = [];
      let result =
          colsArr.reduce((acc, cols) => {
            switch ( cols.length ) {
              case 0 :
                return acc;
              case 1 :
                acc.push(this.createFragment({ name: cols[ 0 ], newId }));
                newId--;
                return acc;
              default: {
                let columnPtr = 0;
                let fragment = this.createFragment({ name: cols[ columnPtr ], newId });
                newId--;
                columnPtr++;

                if (cols[ 1 ].trim().toLowerCase() === 'f') {
                  fragment.action = 'fragmentAnalyze';
                  fragment.primersRls = [{
                    fragmentId: fragment.id,
                    orderId: this.editOrder.id
                  }];
                  columnPtr++;
                }

                if ((columnPtr <= cols.length - 1) && cols[ columnPtr ].search(/^[0-4]$/g) < 0) {
                  let type = _.find(this.fragmentTypes, t => t.name.toLowerCase() === cols[ columnPtr ].toLowerCase());

                  if (type) {
                    fragment.type = type;
                    fragment.typeId = type.id;
                    columnPtr++;
                  }
                }

                if ((columnPtr <= cols.length - 1) && fragment.action === 'sequence' && columnPtr < cols.length && cols[ columnPtr ].search(/^[0-4]$/g) === 0) {
                  fragment.option = ['none', 'clean', 'cleanPcr', 'cleanGel', 'plasmidExtract'][ cols[ columnPtr ] * 1 ];
                  columnPtr++;
                }

                if (columnPtr <= cols.length - 1) {
                  fragment.tubeTitle = cols[ columnPtr ];
                }

                acc.push(fragment);
                return acc;
              }
            }
          }, []);

      errors.push(...this.getDuplicateByName(result));

      if (errors.length > 0) {
        return errors;
      }

      this.editOrder.elements.push(...result);
      return null;
    },

    prepareSequence(src, errors, lineIdx) {
      let primer = this.$primerHelper.parse(src, false);
      primer.scale = 0.04;
      let prmErrors = this.$primerHelper.getErrorMessages(primer, 'no');
      if (prmErrors.filter(err => err.code !== 'empty').length > 0) {
        errors.push(...prmErrors.map(e => ({ fatal: true, message: `#${lineIdx + 1}  ${src} - ${e.message}` })));
        return null;
      }
      return primer.sequence;
    },

    doImportPrimers(colsArr) {
      let errors = [];
      let newId = _.min(this.editOrder.primers.map(p => p.id));
      newId = !newId || newId > 0 ? -1 : newId;

      let result =
          colsArr.reduce((acc, cols, lineIdx) => {
            newId--;
            switch ( cols.length ) {
              case 0 :
                return acc;
              case 1 : {
                let saveErrorCount = errors.length;
                let sequence = this.prepareSequence(cols[ 0 ], errors, lineIdx);
                if (errors.length === saveErrorCount) {
                  acc.push({
                             id: newId,
                             sequence,
                             name: '',
                             storeType: 'fromUser',
                             actionAfterEnd: 'destroy'
                           });
                }
                return acc;
              }
              default: {
                if (cols[ 1 ] === '1' || cols[ 1 ] === '0') {
                  let saveErrorCount = errors.length;
                  let sequence = this.prepareSequence(cols[ 0 ], errors, lineIdx);
                  if (errors.length === saveErrorCount) {
                    acc.push({
                               id: newId,
                               name: '',
                               sequence,
                               storeType: cols[ 1 ] === '1' ? 'fromOrder' : 'fromUser',
                               actionAfterEnd: 'destroy'
                             });
                  }
                } else {
                  let saveErrorCount = errors.length;
                  let sequence = this.prepareSequence(cols[ 1 ], errors, lineIdx);
                  if (errors.length === saveErrorCount) {
                    acc.push({
                               id: newId,
                               name: cols[ 0 ],
                               sequence,
                               storeType: cols.length > 2 && cols[ 2 ] === '1' ? 'fromOrder' : 'fromUser',
                               actionAfterEnd: 'destroy'
                             });
                  }
                }
                return acc;
              }
            }
          }, []);

      errors.push(...this.getDuplicateByName(result).map(r => ({ fatal: true, message: r })));

      if (errors.length > 0) {
        return errors;
      }

      result.forEach(p => this.syncPrimerOrder(p));
      this.editOrder.primers.push(...result);
      return null;
    },

    findFragmentType(srcType) {
      if (['пцр', 'pcr'].some(fnd => srcType?.toLowerCase().includes(fnd))) {
        return this.fragmentTypes.find(t => t.id === 2);
      } else if (['вектор', 'vec', 'плазм'].some(fnd => srcType.toLowerCase().indexOf(fnd) >= 0)) {
        return this.fragmentTypes.find(t => t.id === 1);
      }
      return null;
    },

    async doImportAll(colsArr) {

      let errors = [];
      let newId = _.min(this.editOrder.primers.map(p => p.id));
      newId = !newId || newId > 0 ? -1 : newId;
      let newFragmentId = _.min(this.editOrder.elements.map(e => e.id));
      newFragmentId = (!newFragmentId || newFragmentId > 0) ? -1 : (newFragmentId - 1);

      let mails = '';
      let result =
          colsArr.reduce((acc, cols, lineIdx) => {
            let fragmentName = cols[ 1 ];
            let primerName = cols[ 2 ];
            let dnaSize = cols[ 5 ]?.trim();
            let primerConcentration = '';
            let fragmentType = '';
            let primerSequence = '';
            let tubeTitle = '';

            switch ( cols.length ) {
              case 1: {
                if (cols[ 0 ].trim().indexOf('@:') === 0) {
                  mails = cols[ 0 ].trim().substring(2).trim();
                }
                return acc;
              }

              case 0:
                return acc;
              case 2:
              case 3: {
                fragmentName = cols[ 1 - (3 - cols.length) ].trim();
                primerName = cols[ 2 - (3 - cols.length) ].trim();
                break;
              }
              case 5:
              case 6:
              case 7:
              case 8: {
                fragmentName = cols[ 1 ].trim();
                primerName = cols[ 2 ].trim();
                primerConcentration = cols[ 3 ].trim();
                if (primerConcentration.trim() && primerConcentration.search(/[0-9]/i) === -1) {
                  errors.push({ fatal: false, message: `#${lineIdx + 1} В поле коцентрация что-тоесть, а цифр нет. А должны.` });
                }
                fragmentType = cols[ 4 ].trim();
                primerSequence = cols.length > 6 ? this.cleanPrimerSequence(cols[ 6 ]) : '';
                if (cols.length === 8) {
                  tubeTitle = cols[ 7 ].trim();
                }
                break;
              }
              default: {
                errors.push({ fatal: true, message: `#${lineIdx + 1} Ошибочное количество столбцов` });
                return acc;
              }
            }
            fragmentName = removeEndSemicolon(fragmentName);
            primerName = removeEndSemicolon(primerName);

            let fndPrimer = _.find(acc.primers, f => f.name.toLowerCase().replace(/ /g, '') === primerName.toLowerCase().replace(/ /g, ''));
            if (!fndPrimer) {
              let saveErrorCount = errors.length;
              let sequence = this.prepareSequence(primerSequence, errors, lineIdx);
              if (errors.length === saveErrorCount) {
                fndPrimer = {
                  id: newId,
                  sequence,
                  name: primerName,
                  storeType: 'fromUser',
                  actionAfterEnd: 'destroy',
                  concentration: primerConcentration
                };
                acc.primers.push(fndPrimer);
                newId--;
              } else {
                return acc;
              }
            } else {
              if (fndPrimer.sequence !== primerSequence?.toUpperCase()) {
                errors.push({ fatal: true, message: `${fragmentName}  -  ${primerName}  - последовательности с одинаковым названием в разных строках не совпадают.` });
                return acc;
              }
            }

            let fndFragment = _.find(acc.fragments, f =>
                f.name.toLowerCase().replace(/ /g, '') === fragmentName.toLowerCase().replace(/ /g, '')
                && f.tubeTitle.toLowerCase().replace(/ /g, '') === tubeTitle.toLowerCase().replace(/ /g, ''));
            if (!fndFragment) {
              let type = this.findFragmentType(fragmentType);
              fndFragment = this.createFragment({ name: fragmentName, type, newId: newFragmentId, tubeTitle, dnaSize });
              newFragmentId--;
              acc.fragments.push(fndFragment);
            }
            if (fndFragment.primersRls.some(rl => rl.primer.name.toLowerCase().replace(/ /g, '') === fndPrimer.name?.toLowerCase().replace(/ /g, ''))) {
              errors.push({ fatal: false, message: `${fragmentName}  -  ${primerName}  - дублирующиеся строки.` });
            }

            this.addPrimerToFragment(fndFragment, fndPrimer, lineIdx + 1);
            return acc;
          }, { fragments: [], primers: [] });

      if (errors.filter(e => e.fatal).length > 0) {
        return errors;
      }
      if (errors.length > 0) {
        let res = await confirmWithLog('Были ошибки',
                                       errors.reduce((acc, err) => acc + (acc ? '\n' : '') + err.message, ''),
                                       'Импорт', 'Продолжить', 'Не продолжать');

        if (!res) {
          return []; //Если массив ошибок есть но не пуст - не показываем повторно окошко с ошибкам
        }
      }

      if (mails.length > 0) {
        this.editOrder.mailsForResult = mails;
      }
      this.editOrder.elements = this.editOrder.elements.filter(el => el.id > 0);
      this.editOrder.elements.forEach(el => {
        el.forDelete = true;
        el.primersRls = el.primersRls.filter(rl => rl.id > 0);
        el.primersRls.forEach(rl => rl.forDelete = true);
      });
      this.editOrder.elements.push(...result.fragments);

      this.editOrder.primers = this.editOrder.primers.filter(pr => pr.id > 0);
      this.editOrder.primers.forEach(pr => pr.forDelete = true);
      this.editOrder.primers.push(...result.primers);
      return null;

      function removeEndSemicolon(src) {
        if (src.endsWith(";")) {
          return src.substring(0, src.length - 1);
        }
        return src;
      }
    },

    cleanPrimerSequence(src) {
      src = src.replace(/\s+/g, '');
      if (!src || src === '-') {
        return '';
      }
      src = src.replace(/^5[`']-?/, '');
      src = src.replace(/;$/, '');
      src = src.replace(/-?3[`']$/, '');
      return src;
    },

    cleanNotUseStorePrimers() {
      let allPrimersInFragments = this.editOrder.elements.reduce((acc, frg) => acc.concat(frg.primersRls.map(el => el.primerId)), []);
      this.editOrder.primers
      .filter(p => (p.storeType === 'fromUserLib' || p.storeType === 'stdLib')
          && !allPrimersInFragments.some(id => id === p.id))
      .forEach(pr => {
        const idx = this.editOrder.primers.indexOf(pr);
        if (this.editOrder.primers[ idx ].id > 0) {
          this.editOrder.primers[ idx ].forDelete = true;
        } else {
          this.editOrder.primers.splice(idx, 1)
        }
      });
    },

    async onBtnPrimerEdit(primer) {
      let saveStoreType = primer?.storeType;
      primer = await this.$showWindowAsync(SeqPrimerEditor, {
        allPrimers: this.editOrder.primers,
        primer: primer ? _.cloneDeep(primer) : this.createNewPrimer('fromUser')
      });
      if (!primer) {
        return;
      }
      let idx = _.findIndex(this.editOrder.primers, e => e.id === primer.id);
      if (idx < 0) {
        this.editOrder.primers.push(primer);
      } else {
        this.editOrder.primers.splice(idx, 1, primer);
      }
      await this.syncPrimerOrder(primer, saveStoreType);
    },

    createNewPrimer(storeType) {
      let id = _.min(this.editOrder.primers.map(e => e.id));
      id = !id || id > 0 ? -1 : (id - 1);
      return {
        id,
        primerOrder: null,
        primerOrderId: null,
        orderId: 0,
        name: '',
        sequence: '',
        storeType,
        actionAfterEnd: 'destroy',
        forDelete: false
      }
    },

    async onClickRemovePrimerFromFragment(row, isFragmentAnalyze) {
      if (!await confirm(isFragmentAnalyze ? 'Удалить фрагментный анализ?' : 'Отвязать праймер от фрагмента?')) {
        return;
      }
      let idx = row.fragment.primersRls.indexOf(row.primerRl);
      if (row.fragment.primersRls[ idx ].id > 0) {
        row.fragment.primersRls[ idx ].forDelete = true;
      } else {
        row.fragment.primersRls.splice(idx, 1);
      }
      this.cleanNotUseStorePrimers();
    },

    async onBtnPrimerDelete(primer, withConfirm = true) {
      if (withConfirm && !await confirm("Удалить праймер")) {
        return;
      }
      this.editOrder.elements.forEach(fragment => {
        for ( ; ; ) {
          let idx = _.findIndex(fragment.primersRls, p => !p.forDelete && p.primerId === primer.id);
          if (idx >= 0) {
            if (fragment.primersRls[ idx ].id > 0) {
              fragment.primersRls[ idx ].forDelete = true;
            } else {
              fragment.primersRls.splice(idx, 1);
            }
            continue;
          }
          break;
        }
      });
      if (primer.storeType === 'fromOrder') {
        this.deleteOrderPrimer(primer);
      }
      let idx = this.editOrder.primers.indexOf(primer);
      if (this.editOrder.primers[ idx ].id > 0) {
        this.editOrder.primers[ idx ].forDelete = true;
      } else {
        this.editOrder.primers.splice(idx, 1);
      }
    },

    deleteOrderPrimer(primer) {
      let order = _.find(this.metaorder.orders, o => o.id === primer.primerOrderId);
      let ordPrimerIdx = _.findIndex(order.elements, p => p.id === primer.primerId);
      if (order.elements[ ordPrimerIdx ].id > 0) {
        order.elements[ ordPrimerIdx ].forDelete = true;
      } else {
        order.elements.splice(ordPrimerIdx, 1);
      }
      if (order.elements.filter(e => !e.forDelete).length === 0) {
        removeOrder(order.id, this.metaorder, this.deliverys);
      } else {
        recalcPrimerOrder(this.primerOrder);
      }
      return true;
    },

    async syncPrimerOrder(primer, saveStoreType) {
      //Праймер был для синтеза, но его тип изменили
      if (primer.storeType !== 'fromOrder' && saveStoreType === 'fromOrder') {
        this.deleteOrderPrimer(primer);
        primer.primerOrderId = null;
        primer.primerOrder = null;
        primer.primerId = null;
        primer.primer = null;
      }
      if (primer.storeType !== 'fromOrder') {
        return;
      }

      if (!this.primerOrder) {
        createNewSubOrder(this.metaorder, this.deliverys, this.$settings.deadlines, 'sst', 'forSequence');
      }
      let ordPrimer = null;
      if (primer.primerOrderId) {
        ordPrimer = _.find(this.primerOrder.elements, p => p.id === primer.primerId);
        ordPrimer.sequence = primer.sequence;
        ordPrimer.name = primer.name;
      } else {
        let newId = _.min(this.primerOrder.elements.map(e => e.id));
        newId = !newId || newId > 0 ? -1 : (newId - 1);
        ordPrimer =
            {
              id: newId,
              orderId: this.primerOrder.id,
              name: primer.name,
              scale: 0.04,
              sequence: primer.sequence,
              optionsCleaning: false,
              optionsKeen: false,
              primerModifierRls: [],
              price: 0,
              discountPrice: 0
            };
        this.primerOrder.elements.push(ordPrimer);
        primer.primerOrderId = ordPrimer.orderId;
        primer.primerId = ordPrimer.id;
      }
      await loadPrimerPrice(this, ordPrimer, this.primerOrder.discountPercent);
      recalcPrimerOrder(this.primerOrder);
    },

    onPrimersSelectionChange(val) {
      this.primerSelections = val;
    },

    onFragmentSelectionsForSplitChange(val) {
      this.fragmentSelectionsForSplit = val;
    },

    onFragmentSelectionChange(val) {
      this.fragmentSelections = val.filter(row => row.number === 0);
    },

    addPrimerToFragmentIfNeed(fragment, primer, prior = null) {
      if ((!primer) || !fragment.primersRls.some(el => !el.forDelete && el.primerId === primer.id)) {
        fragment.primersRls.push(
            {
              fragmentId: fragment.id,
              primerId: primer ? primer.id : null,
              primer: primer,
              orderId: this.editOrder.id < 0 ? 0 : this.editOrder.id,
              prior: prior || this.primerRlMaxPrior + 1
            });
      }
    },

    //При импорте если возникают дубли - их разрешаем, в отличии от addPrimerToFragmentIfNeed
    addPrimerToFragment(fragment, primer, prior) {
      let rl = {
        fragmentId: fragment.id,
        primerId: primer ? primer.id : null,
        primer: primer,
        orderId: this.editOrder.id < 0 ? 0 : this.editOrder.id,
        prior: prior || this.primerRlMaxPrior + 1
      };
      let existPrimer = fragment.primersRls.find(rl => rl.primer === primer);
      if (existPrimer) {
        if (!existPrimer.copyNumber) {
          existPrimer.copyNumber = 0;
        }
        rl.copyNumber = Math.max(...fragment.primersRls.filter(rl => rl.primer === primer).map(rl => rl.copyNumber)) + 1;
      }
      fragment.primersRls.push(rl);
    },

    async onBtnBindClick() {
      let warning = '';
      let errors = '';
      this.fragmentSelections.filter(r => r.number === 0).forEach(fragmentRow => {
        if (fragmentRow.fragment.action !== 'sequence') {
          warning += `Фрагментный анализ - игнорирую: ${fragmentRow.fragment.name}<br/>`;
          return;
        }
        this.primerSelections.filter(r => r.storeType === 'stdLib').forEach(primer => {
          if (!primer.store.types.some(t => t.sequenceTypeId === fragmentRow.fragment.typeId)) {
            warning += `Несоответствие типов:  ${fragmentRow.fragment.name}  -  ${primer.store.name}<br/>`;
          }
        });
      });

      if (errors) {
        await alert(errors);
        return;
      }

      if (warning) {
        warning = `Предупреждения:<br/><br/>${warning}<br/> Продолжить?`;
        if (!await confirm(warning)) {
          return;
        }
      }

      this.fragmentSelections.filter(r => r.number === 0 && r.fragment.action === 'sequence').forEach(fragmentRow => {
        this.primerSelections.forEach(primer => {
          this.addPrimerToFragmentIfNeed(fragmentRow.fragment, primer);
        });
      });

      let selCopy = _.cloneDeep(this.fragmentSelections);
      this.$nextTick(() =>
                         this.tableRows.filter(tr => selCopy.some(fs => fs.fragment.id === tr.fragment.id))
                         .forEach(row => {
                           this.$refs.tblFragments.toggleRowSelection(row, true);
                         }));

      this.primerSelections.forEach(s => this.$refs.tblPrimers.toggleRowSelection(s, false));
    },

    arraySpanMethod({ row, columnIndex }) {
      if ([0, 1, 2, 3, 6].indexOf(columnIndex) >= 0) {
        return row.number === 0
            ? [row.count, 1]
            : [0, 0]
      }
    },

    tableRowClassName({ row }) {
      return row.fragmentNumber % 2 === 0 ? 'row-even' : 'row-odd';
    },

    selectedRowClassName({ row, rowIndex }) {
      let isSelectedRow = this.fragmentSelectionsForSplit.some(fs => fs === row);
      let result = '';
      if (isSelectedRow) {
        result = 'selected-row';
      } else {
        result = (rowIndex % 2 === 0) ? 'row-even' : 'row-odd';
      }
      result += ['overload', 'error', 'canceled'].includes(row.primerRl.state) ? ' bad-row' : '';
      return result;
    },

    getSequence(fragmentElement) {
      if (!fragmentElement) {
        return '';
      }

      fragmentElement = this.editOrder.primers.find(p => p.id === fragmentElement.id);

      let primer = '';
      switch ( fragmentElement.storeType ) {
        case 'fromUser' : {
          primer = fragmentElement.sequence;
          break;
        }
        case 'stdLib': {
          return '';
        }
        case 'fromUserLib' : {
          primer = fragmentElement.store?.sequence;
          break;
        }
        case 'fromOrder' : {
          let order = _.find(this.metaorder.orders, o => o.id === fragmentElement.primerOrderId);
          primer = _.find(order.elements, e => e.id === fragmentElement.primerId)?.sequence;
          break;
        }
        default:
          return '';
      }

      let editView = this.$primerHelper.buildEditView(this.$primerHelper.parse(primer, false));
      return primer ? this.$primerHelper.buildHtml(editView, null) : '';
    },

    getName(fragmentElement) {
      if (!fragmentElement) {
        return '';
      }

      fragmentElement = this.editOrder.primers.find(p => p.id === fragmentElement.id);
      switch ( fragmentElement.storeType ) {
        case 'fromUser' : {
          return fragmentElement.name + ' (От заказчика)';
        }
          // case 'fromUserLib' :
        case 'stdLib': {
          return fragmentElement.name + ' (Cтандартные)';
        }
        case 'fromOrder' : {
          let order = _.find(this.metaorder.orders, o => o.id === fragmentElement.primerOrderId);
          return _.find(order.elements, e => e.id === fragmentElement.primerId)?.name;
        }
        default:
          return fragmentElement.name;
      }
    },

    getStoreType(fragmentElement) {
      const dct = {
        fromUserLib: 'Библиотека заказчика',
        stdLib: 'Евроген',
        fromUser: 'От заказчика',
        fromOrder: 'В заказе',
      };
      return dct[ fragmentElement.storeType ];
    },

    getConcentration(fragmentElement) {
      if (!fragmentElement) {
        return '';
      }

      switch ( fragmentElement.storeType ) {
        case 'stdLib' :
        case 'fromUser' : {
          return fragmentElement.concentration;
        }
        case 'fromUserLib' : {
          return fragmentElement.store.concentration;
        }
        default:
          return '';
      }
    },

    canEditPrimer(primer) {
      switch ( primer.storeType ) {
        case 'fromOrder' : {
          let order = _.find(this.metaorder.orders, o => o.id === primer.primerOrderId && !o.forDelete);
          return !order.state || order.state === 'newOrder' || order.state === 'notSet';
        }
        case 'stdLib':
        case 'fromUserLib': {
          return false;
        }
      }
      return true;
    },

    errorsCommon() {
      if (!this.lockManager.canEdit) {
        return 'Заказ редактируется другим пользователем.';
      }
      if (!['waitDelivery', 'waitPrimers', 'waitFirstCheck', 'waitCheck'].some(s => s === this.editOrder.state)) {
        return 'Заказ в состоянии когда изменять его состав нельзя.'
      }
    },

    errorsForBtnEditPrimer(primer) {
      let err = this.errorsCommon();
      if (err) {
        return err;
      }
      switch ( primer.storeType ) {
        case 'fromOrder' : {
          let order = _.find(this.metaorder.orders, o => o.id === primer.primerOrderId && !o.forDelete);
          return order.state === 'newOrder' ? '' : 'Для этого праймера есть заказ в производстве (или произведен).';
        }
        case 'fromUserLib':
        case 'stdLib': {
          return 'Это праймер из библтотеки - его нельзя отредактировать';
        }
      }
      return '';
    },

    errorsForBtnDeletePrimer(primer) {
      let err = this.errorsCommon();
      if (err) {
        return err;
      }
      if (primer.storeType === 'fromOrder') {
        let order = _.find(this.metaorder.orders, o => o.id === primer.primerOrderId && !o.forDelete);
        return order.state === 'newOrder' ? '' : 'Для этого праймера есть заказ в производстве (или произведен).';
      }
      return '';
    },

    onAutocompleteFragmentTypeSelect(type) {
      this.acFragmentTypeValue = type;
    },

    async onBtnSetOrderInProgress() {
      this.lockManager.watchIsOn = false;
      try {
        await this.$store.dispatch('orders/setOrderCheckResult', { id: this.editOrder.id, commentRecord: null, state: 'inProgress' });
      } catch (ex) {
        alert("Не удалось изменить статус заказа: " + ex.message);
      } finally {
        this.lockManager.watchIsOn = true;
      }
      this.reloadOrder();
    },

    getFileExtensionsForElement(row) {
      return this.sequenceFiles[ row.primerRl.id ] || [];
    },

    async getSequenceFile(rlId, fileName) {
      await this.$myHttp.postWithDownload('/api/files/GetSequenceFile',
                                          {
                                            relationId: rlId,
                                            folder: 'order',
                                            fileName: fileName
                                          },
                                          fileName
      );
    },

    async getSequenceZipFile() {
      const fileName = this.editOrder.number + '.zip';
      await this.$myHttp.postWithDownload(`/api/files/GetSequenceZipFile`, this.editOrder.id, fileName);
    },

    setSelectionMaterialFragments(rows) {
      this.selectedMaterialFragments = rows;
    },

    setSelectionMaterialPrimers(rows) {
      this.selectedMaterialPrimers = rows;
    },

    setMaterialsForGroup(val) {
      this.selectedMaterialFragments.forEach(f => f.actionAfterEnd = val);
      this.selectedMaterialPrimers.forEach(f => f.actionAfterEnd = val);
    },

    copyFragmentPrimerRl(row) {
      let clone = _.clone(row.primerRl);
      clone.id = 0;
      row.fragment.primersRls.push(clone);
    },

    async showPlate(primerRl) {
      await this.$showWindowAsync(sequencePlateEditor, { id: primerRl.plate.id, number: primerRl.plate.number, selectedId: primerRl.id });
    },


    getLastSequenceCheck(checkRls) {
      switch ( checkRls.length ) {
        case 0:
          return null;
        case 1:
          return checkRls[ 0 ];
        default: {
          return _.maxBy(checkRls, 'id');
        }
      }
    },

    async showCheckWindow(checkRl) {
      if (!checkRl) {
        return;
      }
      let check = await this.$showWindowAsync(sequenceCheckEditor, { id: checkRl.checkId });
      if (!check) {
        return;
      }
      this.editOrder.elements
      .map(el => this.getLastSequenceCheck(el.sequenceCheckRls))
      .filter(rl => rl && rl.checkId === check.result.id)
      .forEach(rl => {
        rl.check = check.result;
      });
    },

    prepareOptionPrice() {
      this.editOrder.elements
      .filter(e => !e.forDelete)
      .forEach(fragment => {
        if (!fragment.optionPrice) {
          fragment.optionPrice = fragment.option !== 'none'
              ? this.prices[ fragment.option ]
              : 0;
        }
      });
    },

    calcOrderPrice(withDiscount) {
      let summaryArr = seqOrderSummary(this.editOrder);
      return summaryArr.reduce((acc, item) => acc + (withDiscount ? item.discountSum : item.sum), 0);
    },

    onChangesElements(changes) {
      if (changes.sequenceNoteList) {
        if (changes.sequenceNoteList.insert.length > 0) {
          let newNote = changes.sequenceNoteList.insert[ 0 ];
          if (!this.noteLists.some(n => n.id === newNote.id))
            this.noteLists.push(newNote);
        }
        if (changes.sequenceNoteList.update.length > 0) {
          if (changes.sequenceNoteList.update[0].isArchive) {
            this.noteLists = this.noteLists.filter(n => n.id !== changes.sequenceNoteList.update[0].id);
          }
        }
      }
    }

  },

  computed: {
    isNoteTabsWindow() {
      return window.name === 'seqNoteLists';
    },

    sortedNoteLists() {
      return _.orderBy(this.noteLists, 'id', 'desc');
    },


    monitoringItems() {
      return {
        sequenceNoteList: {
          actions: ['insert', 'update', 'delete'],
          ids: this.noteLists.map(nl => nl.id),
          insertExist: true,
          dispatch: 'sequenceNotes/loadItems'
        }
      }
    },


    error() {
      if (this.editOrder.elements.length === 0 && this.editOrder.primers.length === 0) {
        return 'Ну хоть что-нибудь в заказе быть должно. Хоть фрагмент какой. Или праймер. А лучше и то и другое.';
      }
      return '';
    },


    stateIsWaitButResultIsSend() {
      return this.editOrder.state === 'waitSendResult'
          && this.editOrder.stateLog.some(l => l.newState === 'done' || l.newState === 'readyToSend')
    },
    isShowDoneButton() {
      return !this.simpleDeliveryForOrder &&
          (this.editOrder.state === 'readyToSend' || this.stateIsWaitButResultIsSend);
    },
    isShowReadyToSendButton() {
      return this.simpleDeliveryForOrder && this.stateIsWaitButResultIsSend;
    },

    orderStateTitle() {
      if (this.editOrder.state === 'inProgress' && !this.checkOrderPay) {
        return 'Ожидает оплаты';
      } else if (this.editOrder.state === 'readyToSend') {
        return this.simpleDeliveryForOrder ? 'Возврат образцов' : 'Ожидает завершения';
      }
      return this.$getEnumValue('OrderStateEnum', this.editOrder.state);
    },

    counters() {
      let arr = seqOrderSummary(this.editOrder);
      return Object.fromEntries(arr.filter(el => el.count > 0).map(el => [el.key, { count: el.count, sum: el.discountSum }]));
    },

    primerRlMaxPrior() {
      return _.max(this.editOrder.elements.map(el => _.maxBy(el.primersRls, rl => rl.prior || 0)?.prior || 0)) || 0;
    },

    simpleDeliveryForOrder() {
      return this.deliverys.find(dlv => dlv.type === 'simple' && dlv.ordersRl.some(rl => !rl.forDelete && rl.orderId === this.editOrder.id));
    },

    filterForPrimerMaterials() {
      return this.editOrder.primers.filter(p => !p.forDelete && (p.storeType === 'fromUser' || p.storeType === 'fromOrder'))
    },

    preparedHandPrice: {
      get: function() {
        return this.editOrder.handPrice === null ? undefined : this.editOrder.handPrice;
      }
      ,
      set: function(value) {
        this.editOrder.handPrice = value;
      }
    },

    computedOrderPrice() {
      return this.calcOrderPrice(false);
    },

    tableRows() {
      let id = -1;
      return this.editOrder.elements.filter(el => !el.forDelete).reduce((res, fragment, fragmentNumber) => {
        let number = -1;
        let items;
        if (fragment.primersRls.filter(rl => !rl.forDelete).length > 0) {
          items = fragment.primersRls.filter(rl => !rl.forDelete).map(primerRl => {
            number++;
            id++;
            return {
              id, number, fragment, primerRl, fragmentNumber,
              count: fragment.primersRls.filter(rl => !rl.forDelete).length,
              checkRl: this.getLastSequenceCheck(fragment.sequenceCheckRls)
            };
          });
        } else {
          number++;
          id++;
          items = [{
            id, number, fragment, fragmentNumber,
            primerRl: null,
            count: 1,
            checkRl: this.getLastSequenceCheck(fragment.sequenceCheckRls)
          }];
        }
        return res.concat(items);
      }, []);
    }
    ,

    tableRowsAll() {
      return this.editOrder.elements
      .filter(f => !f.forDelete && f.primersRls.length > 0)
      .reduce((acc, f) => acc.concat(f.primersRls
                                     .filter(rl => !rl.forDelete)
                                     .map(p => ({
                                       prior: p.prior,
                                       fragment: f,
                                       primerRl: p,
                                       checkRl: this.getLastSequenceCheck(f.sequenceCheckRls)
                                     }))), []);
    }
    ,

    primerOrder() {
      return _.find(this.metaorder.orders, o => !o.forDelete && o.forSequence && (!o.state || o.state === 'newOrder'));
    },

    prices() {
      return this.$store.state.prices.sequencePrices;
    }
    ,

    existPrimersInProgress() {
      return this.editOrder.primers.filter(p => !p.forDelete)
      .some(p => p.storeType === 'fromOrder'
          && this.metaorder.orders.some(o => !o.forDelete && o.id === p.primerOrderId && o.state !== 'done'));
    }
    ,

    errorsForBtnCheck() {
      if (!this.lockManager.canEdit) {
        return 'Заказ редактируется другим пользователем.';
      }
      if (this.tableRowsAll.length === 0) {
        return 'В заказе нет элементов.';
      }
      if (this.tableRowsAll.some(r => r.fragment.action !== 'fragmentAnalyze' && !r.fragment.type)) {
        return 'Не для всех образцов задан тип';
      }
      return '';
    }
    ,
    errorsForBtnInProgress() {
      if (!this.lockManager.canEdit) {
        return 'Заказ редактируется другим пользователем.';
      }
      if (this.existPrimersInProgress) {
        return 'Не все праймеры для заказа готовы'
      }
      return '';
    }
    ,
    noDeletedPrimers() {
      return this.editOrder.primers.filter(p => !p.forDelete);
    }
  }
}
</script>

<style>

.element-table .el-table__body-wrapper {
  overflow-y: scroll !important;
}

.layout-container .view {
  border: 0 !important;
}

.error-check-row {
  background-color: #ffdad0 !important;
}

.views {
  margin: 0 !important;
}

.selected-row {
  background-color: #ecf5ff !important;
}

.bad-row td div {
  opacity: 0.4;
}

.bad-row td div div {
  opacity: 1;
}

.even-row {
  background-color: #FDF5E6 !important;
}

</style>
