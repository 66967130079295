<template>
  <float-window @close="$emit('close')" v-if="!viewOnly" :header="plateName" :width="viewOnly ? '' : ''">
    <div style="display: flex;flex-direction: column">
      <div style="display:flex;align-items: stretch;">
        <div ref="div" id="div" v-show="inSelect"
             @mousedown="onmousedown"
             @mouseup="onmouseup"
             @mousemove="onmousemove"
        ></div>
        <div :style="gridContainerStyle"
             @mousedown.prevent.stop="onmousedown"
             @mouseup="onmouseup"
             @mousemove="onmousemove"
             ref="tableDiv"
             draggable="false"
        >
          <div class="seq-queue-cell" style="border:#ebeef5 solid 1px; font-size: 12px;font-weight: bold;padding: 0 4px 0 4px;background-color:#ecf5ff">{{ notEmptyCellCount }}/{{
              size.width *
              size.height
            }}
          </div>
          <div class="seq-queue-cell" v-for="cellNum in size.width" :key="'hdr_' + cellNum">{{ cellNum }}</div>
          <template v-for="(rowNum, rowIdx) in size.height">
            <div class="seq-queue-cell" :key="plateChars[rowIdx]">{{ plateChars[ rowIdx ] }}</div>
            <div class="seq-queue-cell seq-queue-border-cell selectable files"
                 v-for="(cellNum, cellIdx) in size.width"
                 :key="plateChars[rowIdx] +'-' + cellIdx" :ref="`trg_${rowIdx}_${cellIdx}`"
                 @dragenter="onDragEnter(rowIdx, cellIdx, $event)"
                 @dragover="onDragOver(rowIdx, cellIdx, $event)"
                 @dragleave="onDragLeave(rowIdx, cellIdx, $event)"
                 @drop="onDrop(rowIdx, cellIdx, $event)"
                 :style="{background: getColorForCell(rowIdx, cellIdx) }"
                 :rowId="rowIdx"
                 :cellId="cellIdx"
                 draggable="false"
            >
              <div title="raw" v-if="filesExistForCell(rowIdx,cellIdx).src"
                   style="display: flex; justify-content: center; align-items: center; width: 80%;height: 80%;background-color: aliceblue;font-size: 12px">r
              </div>
              <div v-else></div>
              <div title=".scf" v-if="filesExistForCell(rowIdx,cellIdx).scf"
                   style="display: flex; justify-content: center; align-items: center; width: 80%;height: 80%;background-color: aliceblue;font-size: 12px">f
              </div>
              <div v-else></div>
              <div title=".ab1" v-if="filesExistForCell(rowIdx,cellIdx).ab1"
                   style="display: flex; justify-content: center; align-items: center; width: 80%;height: 80%;background-color: aliceblue;font-size: 12px">a
              </div>
              <div title=".fsa" v-else-if="filesExistForCell(rowIdx,cellIdx).fsa"
                   style="display: flex; justify-content: center; align-items: center; width: 80%;height: 80%;background-color: aliceblue;font-size: 12px">f
              </div>
              <div v-else></div>
              <div title=".seq" v-if="filesExistForCell(rowIdx,cellIdx).seq"
                   style="display: flex; justify-content: center; align-items: center; width: 80%;height: 80%;background-color: aliceblue;font-size: 12px">s
              </div>
              <div v-else></div>
            </div>
          </template>
        </div>
      </div>
      <div v-if="editPlate && (editPlate.state ==='inProgress'|| editPlate.state ==='packed')" style="display: flex; justify-content: flex-end;margin-bottom: 3px">
        <my-el-button :disabled="canClickSetState('inProgress')" @click="onClickSetState('inProgress')" type="success">В производстве</my-el-button>
        <my-el-button :disabled="canClickSetState('error')|| canClickSetState('overload')" @click="onClickSetState('error')" type="warning">Забраковать</my-el-button>
        <my-el-button :disabled="canClickSetState('canceled')" @click="onClickSetState('canceled')" type="danger" style="margin-right:-2px">Отменить</my-el-button>
      </div>
      <div v-if="editPlate && editPlate.state ==='new'" style="display: flex; justify-content: flex-end;margin-bottom: 3px">
        <el-select v-model="selectedOrder" clearable @change="onSelectOrder" style="margin-left:5px">
          <el-option
              v-for="item in ordersOnPlate"
              :key="item"
              :label="item"
              :value="item">
          </el-option>
        </el-select>
        <my-el-button type="warning"
                      :disabled="isToQueueButtonDisabled"
                      @click="inSelect = false; $emit('remove-from-plate', selectedElements);selectedOrder = null;">В очередь
        </my-el-button>
      </div>

      <div style="display:flex;align-items: flex-start; flex-direction: column; padding: 3px 0 2px 5px; border: #409EFF solid 1px;font-size: 12px;min-height: 65px">
        <template v-if="currentCell">
          <div style="display: flex;justify-content: space-between;align-items: center;width: 100%">
            <div style="display: flex">RAW: <a :href="'file:' + platePath" v-html="platePath"></a></div>
            <div style="display: flex">
              <el-tag v-if="editPlate && (editPlate.state ==='inProgress' || editPlate.state ==='packed')" :style="{backgroundColor: colors[currentCell.state].color}"
                      style="color: black; color: rgb(96, 98, 102);font-family:Helvetica Neue, Arial, Helvetica, sans-serif;  margin-right: 3px" size="mini">
                {{ $getEnumValue('SeqStateEnum', currentCell.state) }}
              </el-tag>
            </div>
          </div>
          <div v-if="packPath">PACK: <a :href="'file:' + packPath" v-html="packPath"></a></div>
          <div>Файл: {{ currentCell.fileNameWoExt }}</div>
          <div style="display: flex;align-items: center"><span style="margin-right: 5px">Заказ:</span>
            <my-order-number @end-edit="$emit('end-edit-order', currentCell.orderInfo.id)" :order-info="currentCell.orderInfo"></my-order-number>
          </div>
        </template>
      </div>
      <div style="display:flex;justify-content:space-between; margin-top: 5px;margin-right: -5px;margin-left: -5px">
        <template v-if="editPlate && editPlate.state ==='new'">
          <div>
            <my-el-button type="success"
                          :disabled="notEmptyCellCount === 0"
                          :dis-popover="notEmptyCellCount === 0 ? 'Плашка пуста' : ''"
                          @click="loadPlateFiles(editPlate); $emit('set-plate-state-in-progress', editPlate)"
                          popover="Закончить набор"
                          icon="el-icon-check"
            ></my-el-button>
          </div>
        </template>
        <template v-if="editPlate && ( editPlate.state ==='inProgress' || editPlate.state ==='packed')">
          <div style="flex-grow: 1">
            <el-select v-model="selectedOrder" clearable @change="onSelectOrder" style="margin-left:5px">
              <el-option
                  v-for="item in ordersOnPlate"
                  :key="item"
                  :label="item"
                  :value="item">
              </el-option>
            </el-select>
          </div>
          <div style="display: flex; justify-content: space-between;  flex-wrap: nowrap">
            <my-el-button style="padding-left:0!important;padding-right:0!important;" type="success" @click="loadPlateFiles(editPlate)" icon="el-icon-refresh"
                          popover="Обновить"></my-el-button>
            <my-el-button style="padding-left:0!important;padding-right:0!important;" type="warning" @click="$emit('set-plate-state-new', editPlate)" icon="el-icon-close"
                          popover="Вернуть плашку в набор"></my-el-button>
            <el-dropdown split-button type="success"
                         @command="onClickImportFileNames('newFormat')"
                         @click="onClickImportFileNames('oldFormat')"
                         style="margin: 0 5px 0 5px;"
                         trigger="click">
              <i class="el-icon-download"></i>
              <el-dropdown-menu slot="dropdown" trigger="click">
                <el-dropdown-item>
                  <div style="font-weight: bold; font-size: 15px">Новый формат</div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>






            <el-dropdown split-button type="success"
                         @command="onClickPlateDoPack(false)"
                         @click="onClickPlateDoPack(true)"
                         style="margin: 0 5px 0 5px;"
                         trigger="click">
              <i class="el-icon-sort"></i>
              <el-dropdown-menu slot="dropdown" trigger="click">
                <el-dropdown-item>
                  <div style="font-weight: bold; font-size: 15px">Без проверки <br/>имен файлов</div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>

            <my-el-button style="padding-left:0!important;padding-right:0!important;" type="success" @click="$emit('set-plate-state-done', editPlate)" icon="el-icon-finished"
                          popover="Завершить"></my-el-button>
          </div>
        </template>
      </div>
      <div style="display:flex;margin-top: 5px;" v-if="editPlate && (editPlate.state ==='inProgress'|| editPlate.state ==='packed')">
        <div
            @click="$refs.fileInput.click()"
            @drop.prevent="onClickLoadSrcFiles"
            @dragover.prevent
            style="display: flex; width: 100%; align-items: center; justify-content: center;padding-bottom: 0;height: 40px;border: dotted 1px rgb(220, 223, 230)">Перетащите сюда файлы или кликните,
          чтобы выбрать
        </div>
        <input ref="fileInput" class="upload-link__inp" type="file" @change="onClickLoadSrcFiles" multiple style="display:none"/>
      </div>
    </div>
  </float-window>
  <div v-else>`
    <div style="display:flex;align-items: stretch;">
      <div :style="gridContainerStyle">
        <div class="seq-queue-cell" style="border:#ebeef5 solid 1px; font-size: 12px;font-weight: bold;padding: 0 4px 0 4px;background-color:#ecf5ff">{{ notEmptyCellCount }}/{{
            size.width *
            size.height
          }}
        </div>
        <div class="seq-queue-cell" v-for="cellNum in size.width" :key="'hdr_' + cellNum">{{ cellNum }}</div>
        <template v-for="(rowNum, rowIdx) in size.height">
          <div class="seq-queue-cell" :key="plateChars[rowIdx]">{{ plateChars[ rowIdx ] }}</div>
          <div class="seq-queue-cell files"
               v-for="(cellNum, cellIdx) in size.width"
               :key="plateChars[rowIdx] +'-' + cellIdx"
               :ref="`trg_${rowIdx}_${cellIdx}`"
               @dragenter="onDragEnter(rowIdx, cellIdx, $event)"
               @dragover="onDragOver(rowIdx, cellIdx, $event)"
               @dragleave="onDragLeave(rowIdx, cellIdx, $event)"
               @drop="onDrop(rowIdx, cellIdx, $event)"
               @click="onClickCell(rowIdx, cellIdx)"
               :style="{background: getColorForCell(rowIdx, cellIdx), border: borderStyle(rowIdx, cellIdx) }"
          >
            <div title="raw" v-if="filesExistForCell(rowIdx,cellIdx).src" style="width: 80%;height: 80%;background-color: aliceblue"></div>
            <div v-else></div>
            <div title=".scf" v-if="filesExistForCell(rowIdx,cellIdx).scf" style="width: 80%;height: 80%;background-color: aliceblue"></div>
            <div v-else></div>
            <div title=".ab1" v-if="filesExistForCell(rowIdx,cellIdx).ab1" style="width: 80%;height: 80%;background-color: aliceblue"></div>
            <div v-else></div>
            <div title=".seq" v-if="filesExistForCell(rowIdx,cellIdx).seq" style="width: 80%;height: 80%;background-color: aliceblue"></div>
            <div v-else></div>
          </div>
        </template>
      </div>
    </div>
  </div>

</template>

<script>
import _ from 'lodash';
import { alert, alertWithLog } from '@/components/common/dialogs/dialogUtils';
import floatWindow from './FloatWindow';

export default {
  name: 'plateViewer',
  components: { floatWindow },
  props: { editPlate: { default: {} }, 'plateMap': { default: null }, viewOnly: { type: Boolean, default: false } },
  data() {
    return {
      images: [],
      fileMap: [],
      plateChars: 'ABCDEFGH',
      currentCell: null,
      inSelect: false,
      isAddSelect: false,
      selection: { left: 0, right: 0, top: 0, bottom: 0 },
      selectedOrder: null,
      colors: {
        error: { color: '#F3D19E', selectedColor: '#E6A23C' },
        canceled: { color: '#f5cecc', selectedColor: '#eca199' },
        inProgress: { color: '#B0DD9B', selectedColor: '#2CCE0E' },
        'new': { color: '#B0DD9B', selectedColor: '#2CCE0E' },
        done: { color: '#5bc0de', selectedColor: '#1534ff' }
      }
    }
  },
  async mounted() {
    this.loadPlateFiles(this.editPlate);
  },

  watch: {
    async editPlate(plate) {
      this.currentCell = null;
      this.selectedOrder = '';
      this.loadPlateFiles(plate);
    }
  },

  methods: {
    onSelectOrder(number) {
      this.plateMap.forEach(arr => arr.filter(arr => arr).forEach(el => el.selected = el.orderInfo.number === number));
    },

    onClickSetState(newState) {
      this.inSelect = false;
      this.$emit('turn-plate-element-defect', { elements: this.selectedElements, newState });
      this.selectedOrder = null;
    },

    canClickSetState(state) {
      if (this.selectedElements.length === 0) return true;
      if ((new Set(this.selectedElements.map(el => el.state))).size > 1) return true;
      let firstElState = this.selectedElements[ 0 ].state;
      return firstElState === state;
    },

    reCalc() {
      this.selectedOrder = '';
      let left = Math.min(this.selection.left, this.selection.right);
      let right = Math.max(this.selection.left, this.selection.right);
      let top = Math.min(this.selection.bottom, this.selection.top);
      let bottom = Math.max(this.selection.bottom, this.selection.top);

      this.$refs.div.style.left = left + 'px';
      this.$refs.div.style.top = top + 'px';
      this.$refs.div.style.width = right - left + 'px';
      this.$refs.div.style.height = bottom - top + 'px';

      let els = this.$refs.tableDiv.getElementsByClassName('selectable');
      for ( let el of els ) {
        let elRect = el.getBoundingClientRect();
        let cell = this.plateMap[ el.attributes.rowId.value ][ el.attributes.cellId.value ];
        if (!cell) {
          continue;
        }
        cell.selecting = intersectRect(elRect, { left, right, top, bottom });
        if (!this.isAddSelect) {
          cell.selected = cell.selecting;
        }
      }

      function intersectRect(r1, r2) {
        return !(r2.left > r1.right ||
            r2.right < r1.left ||
            r2.top > r1.bottom ||
            r2.bottom < r1.top);
      }
    },

    onmousedown(e) {
      if (this.inSelect) {
        this.onmouseup();
        return;
      }
      this.isAddSelect = e.ctrlKey;

      this.inSelect = true;
      this.selection.left = e.clientX;
      this.selection.top = e.clientY;
      this.selection.right = e.clientX;
      this.selection.bottom = e.clientY;
      this.reCalc();
    },

    onmousemove(e) {
      if (!this.inSelect) {
        return;
      }
      this.selection.right = e.clientX;
      this.selection.bottom = e.clientY;
      this.reCalc();
    },

    onmouseup() {
      this.inSelect = false;
      this.selectedElements.forEach(el => {
        el.selected = el.selecting || el.selected;
        el.selecting = false;
      });
      this.currentCell = this.selectedElements.length === 1
          ? this.selectedElements[ 0 ]
          : null;
    },

    filesExistForCell(rowIdx, colIdx) {
      if (!this.editPlate || this.editPlate.state === 'new') {
        return false;
      }
      let key = this.plateChars[ rowIdx ] + (colIdx + 1 < 10 ? '0' : '') + (colIdx + 1);
      let result = this.fileMap && this.fileMap[ key ] ? this.fileMap[ key ] : {};

      return result;
    },

    getColorForCell(rowIdx, colIdx) {
      let obj = this.plateMap[ rowIdx ][ colIdx ];
      if (!obj) {
        return '';
      }
      let color = this.colors[ obj.state ];
      return color[ (obj.selected || obj.selecting) ? 'selectedColor' : 'color' ];

    },

    borderStyle(rowIdx, cellIdx) {
      return this.plateMap[ rowIdx ][ cellIdx ] && this.plateMap[ rowIdx ][ cellIdx ].selected
          ? 'gray solid 3px'
          : 'gray solid 1px';
    },

    //<editor-fold desc="dragAndDrop, mouse"> ----------------------------------------------------------------------------
    onDragEnter(rowIdx, cellIdx, event) {
      if (!this.plateMap[ rowIdx ][ cellIdx ]) {
        this.$refs[ `trg_${rowIdx}_${cellIdx}` ][ 0 ].style.background = '#85ce61';
        event.preventDefault();
        return true;
      }
    },

    onDragOver(rowIdx, cellIdx, event) {
      if (!this.plateMap[ rowIdx ][ cellIdx ]) {
        event.preventDefault();
      }
    },

    onDragLeave(rowIdx, cellIdx, event) {
      if (!this.plateMap[ rowIdx ][ cellIdx ]) {
        this.$refs[ `trg_${rowIdx}_${cellIdx}` ][ 0 ].style.background = '';
        event.preventDefault();
      }
    },

    async onDrop(rowIdx, cellIdx, event) {
      if (this.editPlate && this.editPlate.state === 'new') {
        this.$emit('drop-to-plate', { rowIdx, cellIdx, elId: event.dataTransfer.getData("Text") * 1 })
      }

      if (!this.plateMap[ rowIdx ][ cellIdx ]) {
        this.$refs[ `trg_${rowIdx}_${cellIdx}` ][ 0 ].style.background = '';
        event.preventDefault();
      }
    },

    onClickCell(rowIdx, cellIdx) {
      let element = this.plateMap[ rowIdx ][ cellIdx ];
      if (!element) {
        this.currentCell = null;
        return;
      }
      return this.$emit('cell-click', element.id);
    },

    //</editor-fold>----------------------------------------------------------------------------

    async onClickImportFileNames(format) {
      let result;
      try {
        result = await this.$store.dispatch('sequencePlates/buildPlateDeviceList', { plateId: this.editPlate.id, format });
        this.$myHttp.downloadFromDataLink(result, `${this.editPlate.number}.csv`);
      } catch (ex) {
        await alert(`Ошибка получения файла для прибора: ${ex.message}`);
      }
    },

    async onClickPlateDoPack(withCheckFileNames) {
      let result;
      try {
        result = await this.$store.dispatch('sequenceQueues/doPackFiles', { plateId: this.editPlate.id, withCheckFileNames });
      } catch (ex) {
        alertWithLog('Ошибка при фасовке произошла.', ex.message, `Фасовка плашки ${this.editPlate.number}`);
        return;
      }
      this.fileMap = result.filesMap;
      alertWithLog('Результат фасовки', result.workLog.reduce((acc, l) => `${acc}${l}\n`, ''), `Фасовка плашки ${this.editPlate.number}`);
    },

    async onClickLoadSrcFiles(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      let dataForSend = new FormData();
      dataForSend.append('plateId', this.editPlate.id);
      for ( let i = 0; i < files.length; i++ ) {
        dataForSend.append(`files`, files[ i ]);
      }
      let result;
      try {
        result = await this.$myHttp.post('/api/sequenceQueues/prepareSrcFiles', dataForSend, 'multipart/form-data');
      } catch (ex) {
        alertWithLog('Ошибка произошла при файлов загрузке.', ex.message, `Загрузка файлов для плашки ${this.editPlate.number}`);
        return;
      } finally {
        this.$refs.fileInput.value = "";
      }
      this.fileMap = result.filesMap;
      alertWithLog('Результат загрузки.', result.workLog.reduce((acc, l) => `${acc}${l}\n`, ''), `Загрузка файлов для плашки ${this.editPlate.number}`);
    },

    async loadPlateFiles(plate) {
      if (!plate || plate.state === 'new') {
        this.fileMap = [];
      } else {
        try {
          this.fileMap = await this.$store.dispatch('sequenceQueues/getFilesMap', plate.id)
        } catch (ex) {
          alertWithLog("Ошибка загрузки списка файлов для плашки", ex.message);
        }
      }
    },
  },

  computed: {
    isToQueueButtonDisabled() {
      return this.selectedElements.length === 0
             || this.selectedElements.some(el => el.state === 'done');
    },

    ordersOnPlate() {
      return [...new Set(this.plateMap.reduce((acc, arr) => acc.concat(arr.filter(arr => arr).map(el => el.orderInfo.number)), []))];
    },

    platePath() {
      return `${this.$settings.dataPath}\\sequence\\Raw\\${this.editPlate.number.substring(0, 4)}\\${this.editPlate.number}`;
    },

    packPath() {

      if (!this.currentCell) {
        return '';
      }
      return `${this.$settings.dataPath}\\sequence\\Pack\\${this.editPlate.number.substring(0, 4)}\\${this.editPlate.number}\\${this.currentCell.orderInfo.number}`;
    },


    size() {
      return { width: this.plateMap[ 0 ].length, height: this.plateMap.length };
    },

    gridContainerStyle() {
      let result = {
        display: 'grid',
        gridTemplateColumns: _.repeat("30px ", this.size.width + 1).trim(),
        gridTemplateRows: _.repeat("30px ", this.size.height + 1).trim(),
        alignItems: 'center',
        justifyItems: 'center'
      };
      return result;
    },

    notEmptyCellCount() {
      return this.plateMap.reduce((acc, row) => acc + row.reduce((inAcc, cell) => inAcc + (cell ? 1 : 0), 0), 0);
    },
    selectedElements() {
      return this.plateMap.reduce((acc, row) => acc.concat(row.filter(cell => cell && (cell.selected || cell.selecting))), []);
    },


    plateName() {
      let states = { new: 'Набирается', inProgress: 'В работе', done: 'Завершена', packed: 'Расфасована' };
      return this.editPlate
          ? `${this.editPlate.number} - ${states[ this.editPlate.state ]}`
          : '';
    },
  }
}
</script>

<style>
.seq-queue-cell {
  display: flex;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  margin: 2px;
  padding: 1px;
  cursor: pointer;
  /*
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  */
}

.seq-queue-border-cell {
  border: gray solid 1px;
}

#div {
  border: 1px dotted #000;
  position: fixed;
}

.files {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 50% 50%;
  align-items: center;
  justify-items: center;
}
</style>
